import invariant from 'tiny-invariant';
import { routeUrl } from '../../routesBuilder';
import type { SupportedLocale } from '../../i18n/types';

function transformCart<T extends { createdAt: Date; id: string }>(
  carts: T[],
  locale: SupportedLocale,
): (T & { href: string; informationId: string })[] {
  invariant(Array.isArray(carts), 'transformCart requires carts to be defined');

  return carts.map((cart: T) => ({
    ...cart,
    href: routeUrl('cartApproval', { locale, params: { id: cart.id } as any }),
    informationId: generateIformationId(cart.createdAt, cart.id),
  }));
}
function generateIformationId(createdAt: Date, cartId: string): string {
  const checkTime = (i: number) => (i < 10 ? `0${i}` : i);
  const hours = checkTime(createdAt.getHours());
  const minutes = checkTime(createdAt.getMinutes());
  const seconds = checkTime(createdAt.getSeconds());
  const firstFourChars = cartId.substring(0, 4);
  const result = `${hours}${minutes}${seconds}${firstFourChars}`;
  return result;
}

export default transformCart;
