import { createStyles, makeStyles } from '@material-ui/core/styles';
import ProductImage from '@sprinx/react-com/ProductImage';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import invariant from 'tiny-invariant';
import { ShoppingCartSubject } from '../../../api/shoppingCart';
import ProductDetailLink from '../../../components/ProductDetailLink';
import useProductUrl from '../../../hooks/useProductUrl';

export interface CartSummarySubjectListItemImageProps
  extends StandardDivProps<CartSummarySubjectListItemImageClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemImageClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectListItemImage' };
const useStyles = makeStyles(
  (/* theme: Theme */) =>
    createStyles<CartSummarySubjectListItemImageClassKey, {}>({
      root: {},
    }),
  themeSettings,
);

const CartSummarySubjectListItemImage = React.forwardRef<HTMLDivElement, CartSummarySubjectListItemImageProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });

    invariant(typeof subject.product !== 'string');
    const to = useProductUrl(subject.product);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <ProductDetailLink to={to}>
          <ProductImage src={subject.product.image} size='normal' type='image' />
        </ProductDetailLink>
      </div>
    );
  },
);

CartSummarySubjectListItemImage.displayName = 'CartSummarySubjectListItemImage';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemImage);
