import React from 'react';
import { useRecoilState } from 'recoil';
import {
  ShoppingListItemRecord,
  shoppingListsRenameRequestState,
  useShoppingListsUpdateItem,
} from '../../api/shoppingLists';
import ShoppingListsDialogSave from '../ShoppingListsDialogSave';

const ShoppingListsDialogRename: React.FC = () => {
  const [initialValues, setShoppingListsRenameRequest] = useRecoilState(shoppingListsRenameRequestState);

  const handleClose = React.useCallback(() => {
    setShoppingListsRenameRequest(undefined);
  }, [setShoppingListsRenameRequest]);

  const updateShoppigListItem = useShoppingListsUpdateItem();

  const handleSave = React.useCallback(
    (data: ShoppingListItemRecord): void => {
      updateShoppigListItem(data);
    },
    [updateShoppigListItem],
  );

  return initialValues ? (
    <ShoppingListsDialogSave initialValues={initialValues} open onClose={handleClose} onSave={handleSave} />
  ) : null;
};

ShoppingListsDialogRename.displayName = 'ShoppingListsDialogRename';

export default ShoppingListsDialogRename;
