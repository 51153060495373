import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import invariant from 'tiny-invariant';
import { ShoppingCartSubject } from '../../../api/shoppingCart';
import ProductDetailLink from '../../../components/ProductDetailLink';
import useProductUrl from '../../../hooks/useProductUrl';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface CartSummarySubjectListItemNameProps extends StandardDivProps<CartSummarySubjectListItemNameClassKey> {
  // Add more prop definitions here...
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemNameClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectListItemName' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummarySubjectListItemNameClassKey, {}>({
      root: {
        ...theme.typography.h6,
        color: theme.palette.primary.dark,
      },
    }),
  themeSettings,
);

const CartSummarySubjectListItemName = React.forwardRef<HTMLDivElement, CartSummarySubjectListItemNameProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslateWithFallback();

    invariant(typeof subject.product !== 'string');
    const to = useProductUrl(subject.product);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <ProductDetailLink to={to} variant='inherit'>
          {t(subject.product.name, { acceptString: true })}
        </ProductDetailLink>
      </div>
    );
  },
);

CartSummarySubjectListItemName.displayName = 'CartSummarySubjectListItemName';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemName);
