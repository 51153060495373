import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import {
  ProductStockQuantityProduct,
  useProductAvailability,
  useProductStockQuantityLabel,
} from '../../api/products/productStockInfo';

export interface ProductStockQuantityInfoProps extends StandardDivProps<ProductStockQuantityInfoClassKey> {
  product: ProductStockQuantityProduct;
  size?: 'normal' | 'large';
}

export type ProductStockQuantityInfoClassKey = 'root' | 'available' | 'notAvailable' | 'sizeLarge';

const themeSettings = { name: 'ProductStockQuantityInfo' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.body2,
      fontSize: '100%',
    },
    available: {
      color: theme.palette.success.main,
    },
    notAvailable: {
      color: theme.palette.error.main,
    },
    sizeLarge: {
      ...theme.typography.body2,
    },
  }),
  themeSettings,
);

const ProductStockQuantityInfo = React.forwardRef<HTMLDivElement, ProductStockQuantityInfoProps>(
  ({ className, classes: pClasses, product, size = 'normal' }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const label = useProductStockQuantityLabel(product);
    const avalilable = useProductAvailability(product);

    return (
      <div
        ref={ref}
        className={clsx(
          classes.root,
          {
            [classes.sizeLarge]: size === 'large',
            [classes.available]: avalilable,
            [classes.notAvailable]: !avalilable,
          },
          className,
        )}
      >
        {label}
      </div>
    );
  },
);

ProductStockQuantityInfo.displayName = 'ProductStockQuantityInfo';

export default withThemeProps(themeSettings)(ProductStockQuantityInfo);
