import { makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PriceFieldPreview from '@sprinx/react-mui-fields/PriceFieldPreview';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import { customerPriceGroupState } from '../../../api/customer/customer';
import {
  ShoppingCart,
  shoppingCartAreAllPalettesSelector,
  shoppingCartHasPalettesSelector,
} from '../../../api/shoppingCart';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface CartSummaryTotalsProps extends StandardDivProps<CartSummaryTotalsClassKey> {
  cart: ShoppingCart | undefined;
}

export type CartSummaryTotalsClassKey = 'root' | 'prices';

const themeSettings = { name: 'CartSummaryTotals' };
const useStyles = makeStyles(
  (theme: Theme) => ({
    // root: {
    //   display: 'flex',
    //   justifyContent: 'flex-end',
    //   '& > div': {
    //     width: theme.spacing(30),
    //     '& > button': {
    //       width: '100%',
    //       marginBottom: theme.spacing(2),
    //     },
    //   },
    // },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(2),
    },
    possibleProducts: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.palette.warning.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize * 1.1,
    },
    possibleProductsPalett: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize * 1.1,
    },
    prices: {
      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div': {
          width: 'min-content',
          flexGrow: 'unset',
        },
      },
    },
  }),
  themeSettings,
);

const CartSummaryTotals = React.forwardRef<HTMLDivElement, CartSummaryTotalsProps>(
  ({ cart, className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const currency = useRecoilValue(currencyState);
    const priceGroup = useRecoilValue(customerPriceGroupState);
    const hasPalettes = useRecoilValue(shoppingCartHasPalettesSelector);
    const allPalettes = useRecoilValue(shoppingCartAreAllPalettesSelector);

    const { subjects } = cart?.cartContent || { cartContent: {} };

    const possibleProducts =
      subjects &&
      subjects
        .map((variant) => variant.product['taxonomies'])
        .filter((product) => product.includes(`/variants/${priceGroup}`)).length;
    const t = useTranslateWithFallback();

    return !cart ? null : (
      <div ref={ref} className={clsx(classes.root, className)}>
        <PriceFieldPreview
          label={t('common/totalWithoutTax')}
          value={cart.cartContent.total || 0}
          className={classes.prices}
          currency={currency}
          fullWidth
          align='right'
          alignLabel
          size='large'
        />
        {cart.cartContent.total != cart.cartContent.totalWithTax && (
          <PriceFieldPreview
            label={t('common/totalWithTax')}
            value={cart.cartContent.totalWithTax || 0}
            className={classes.prices}
            currency={currency}
            fullWidth
            align='right'
            alignLabel
          />
        )}
        {possibleProducts && possibleProducts >= 1 ? (
          <div className={classes.possibleProducts}>
            <p>{t('cartSummaryPage/cheaperProducts', { possibleProducts })}</p>
          </div>
        ) : null}
        {hasPalettes && !allPalettes ? (
          <div className={classes.possibleProductsPalett}>
            <p>{t('cartSummaryPage/separateOrderRequired')}</p>
          </div>
        ) : null}
      </div>
    );
  },
);

CartSummaryTotals.displayName = 'CartSummaryTotals';

export default withThemeProps(themeSettings)(CartSummaryTotals);
