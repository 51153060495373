import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React, { useEffect, useMemo } from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import TextField from '@sprinx/react-mui-fields/TextField';
import LockIcon from '@material-ui/icons/LockTwoTone';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Helmet } from 'react-helmet';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import { isAuthenticatedState } from '../../api/appState';
import { AfterPageProps, wrapLayout } from '../../@sprinx/react-after-razzle';
import DialogConfirm from '../../@sprinx/react-mui-components/DialogConfirm';
import { useApiClient } from '../../api/appState';
import useLoginHandler from '../../api/auth/useLoginHandler';
import { customerNotificationOpenState } from '../../api/customerNotification';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import LoginRegistrationDialog from '../LoginPage/components/LoginRegistrationDialog';
import InfoLayout from '../../layouts/InfoLayout/InfoLayout';
import { catalogueCallParamsState } from '../../api/products/catalogue';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import { FTP_URL, useAdminConfig } from '../../adminConfig';
import { projectName } from '../../config';
import SimpleImage from './components/SimpleImage';

export type HomePageNewProps = AfterPageProps;
export type HomePageNewClassNameKey =
  | 'root'
  | 'content'
  // | 'headline'
  | 'fields'
  | 'actions'
  // | 'logo'
  | 'loginButton'
  | 'forgotFieldsContainer'
  | 'registrationButton'
  | 'carousel'
  | 'sideLoginPanel'
  | 'hpContent'
  | 'infoBox'
  | 'carouselWrapper'
  | 'imgSection'
  | 'mainWrapper'
  | 'backToImg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<HomePageNewClassNameKey, {}>({
    root: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
      '@media (max-width: 1100px)': {
        paddingTop: '80px',
      },
    },
    imgSection: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    content: {},
    sideLoginPanel: {
      minWidth: '300px',
      maxWidth: '350px',
      padding: theme.spacing(2, 3),
      paddingLeft: '0',
      borderRight: '1px solid #e0e0e0',
      marginRight: '10px',
      '@media (max-width: 1100px)': {
        display: 'flex',
        minWidth: 'unset',
        maxWidth: 'unset',
        flexDirection: 'column',
        borderRight: 'none',
        borderBottom: '1px solid #e0e0e0',
        marginRight: '0',
        paddingRight: '0',
      },
    },
    backToImg: {
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    hpContent: {
      paddingTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '40px',
      maxWidth: '900px',
      width: '100%',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      padding: '0 20px',
      background: 'white',
      border: '1px solid #e0e0e0',
      '@media (max-width: 1100px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    infoBox: {
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      marginBottom: '20px',
      padding: '10px',
      '& > h3': {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#002969',
        background: '#C6C6C6',
        borderTop: `2px solid #002969`,
        borderBottom: `2px solid #002969`,
        padding: '5px',
        marginTop: 0,
      },
      '& > div': {
        paddingLeft: '10px',
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          margin: theme.spacing(2, 0),
        },
        '& > div > a': {
          color: '#002969',
          textDecoration: 'none',
        },
        '& > div > div > a': {
          textDecoration: 'none',
          color: '#042751',
        },
      },
    },
    carouselWrapper: {
      aspectRatio: '16/10',
      minHeight: '200px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '100% !important',
        height: 'auto !important',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    fields: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
      '& > h3': {
        color: '#002969',
        textTransform: 'uppercase',
        marginBottom: 0,
      },
      '& > div': {
        margin: 0,
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      '& > a': {
        cursor: 'pointer',
        marginRight: 'auto',
      },
    },
    forgotFieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    loginButton: {
      backgroundColor: '#002969',
      width: theme.spacing(16),
      color: 'white',
      '&:hover': {
        backgroundColor: '#003485',
      },
    },
    registrationButton: {
      backgroundColor: theme.palette.appBar.background,
      width: theme.spacing(16),
      '&:hover': {
        backgroundColor: lighten(theme.palette.appBar.background, 0.2),
      },
    },
    carousel: {
      '& a': {
        display: 'inline-block',
      },
    },
  }),
);

const initialValues = {
  username: '',
  password: '',
  forgotUser: '',
};

interface Values {
  forgotUser: string;
  password: string;
  username: string;
}

const HomePageNew: React.FC<HomePageNewProps> = ({ history }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<Values>(initialValues);
  const [error, setError] = React.useState<boolean>(false);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openRegistration, setOpenRegistration] = React.useState<boolean>(false);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();
  const setNotificationOpen = useSetRecoilState(customerNotificationOpenState);
  const routeUrlFactory = useRouteUrlFactory();
  const handleChange = React.useCallback(
    (fieldName: keyof Values) => (value: string | undefined) => {
      setValues((ps) => ({ ...ps, [fieldName]: value }));
    },
    [],
  );

  const resetCatalogueCallParamsState = useResetRecoilState(catalogueCallParamsState);

  useEffect(() => {
    resetCatalogueCallParamsState();
  }, []);

  const { leftImageUnderCarousel, rightImageUnderCarousel, homepageMainImage, mainInfoBox, secondaryInfoBox } =
    useAdminConfig();

  const homepageMainImageDocument = useMemo(
    () =>
      homepageMainImage
        ? {
            alt: homepageMainImage.title ?? '',
            src: `${FTP_URL}${homepageMainImage.path}`,
            title: homepageMainImage.title ?? '',
            href: homepageMainImage.url,
          }
        : null,
    [homepageMainImage],
  );

  const handlePasswordReset = React.useCallback(() => {
    if (values.forgotUser) {
      apiClient
        .post<{ email: string }, { username: string }>('/v1/users/renew-password', {
          username: values.forgotUser,
        })
        .then(
          ({ email }) => {
            showMessage({ message: t('loginPage/messages/passwordSent', { email: email || '' }), variant: 'success' });
            setValues(initialValues);
            setOpen(false);
          },
          () => {
            showMessage({ message: t('loginPage/messages/errorUpdatingPassword'), variant: 'error' });
          },
        );

      // ApiClient
      // .then((res) => {
      //    showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
      //    setValues(initialValues);
      //    setOpen(false);
      // })
      // .catch(() => showMessage({ message: 'Neplatné uživatelské jméno', variant: 'error' }))
      // showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
    }
  }, [values, showMessage, apiClient]);

  const handleLogin = useLoginHandler(
    () => {
      setNotificationOpen(true);
      // const afterLoginPath = location?.search
      //   ? (qs.parse(location?.search, { ignoreQueryPrefix: true })?.from as string)
      //   : routeUrlFactory('customerProfile');
      // TODO: add from?
      const afterLoginPath = routeUrlFactory('catalogue');

      history.replace(afterLoginPath);
    },
    (err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Login Error ', err);
      }
      setError(true);
    },
  );
  const [logoutConfirm, setLogoutConfirm] = React.useState<boolean>(false);
  const t = useTranslateWithFallback();

  const handleOpenRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(true);
  }, []);

  const handleCloseRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(false);
  }, []);

  // const handleDrawerToggle = React.useCallback((v) => {
  //   setOpen(typeof v === 'boolean' ? v : (prev) => !prev);
  // }, []);

  return (
    <>
      {/* <AppHeader handleDrawerToggle={handleDrawerToggle} /> */}
      <Helmet>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-75F7P7Y36E'></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-75F7P7Y36E');
        `}
        </script>
      </Helmet>
      <Container className={classes.root}>
        <div className={classes.mainWrapper}>
          <div className={classes.sideLoginPanel}>
            {homepageMainImageDocument && (
              <div>
                <div className={classes.backToImg}>
                  {homepageMainImageDocument?.href ? (
                    <Link href={homepageMainImageDocument.href}>
                      <SimpleImage
                        src={homepageMainImageDocument.src}
                        title={homepageMainImageDocument.title}
                        alt={homepageMainImageDocument.alt}
                      />
                    </Link>
                  ) : (
                    <SimpleImage
                      src={homepageMainImageDocument.src}
                      title={homepageMainImageDocument.title}
                      alt={homepageMainImageDocument.alt}
                    />
                  )}
                </div>
              </div>
            )}
            {mainInfoBox?.body && (
              <div className={classes.infoBox} dangerouslySetInnerHTML={{ __html: mainInfoBox.body }} />
            )}
            {!isAuthenticated && secondaryInfoBox?.body && (
              <div className={classes.infoBox} dangerouslySetInnerHTML={{ __html: secondaryInfoBox.body }} />
            )}
            {!isAuthenticated ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleLogin(values.username, values.password);
                }}
              >
                <div className={classes.content}>
                  <div className={classes.fields}>
                    <h3>{t('loginPage/twoWordsLogin')}</h3>
                    {error && (
                      <Alert severity='error'>
                        <strong>{t('loginPage/loginError')}</strong> {t('loginPage/wrongCredentials')}
                      </Alert>
                    )}
                    <TextField
                      InputProps={{
                        name: 'username',
                        autoComplete: 'username',
                      }}
                      type='text'
                      label={t('common/username')}
                      onChange={handleChange('username')}
                      value={values.username}
                      fullWidth
                      margin='normal'
                    />
                    <PasswordField
                      {...({
                        name: 'password',
                        autoComplete: 'password',
                      } as any)}
                      label={t('common/password')}
                      onChange={handleChange('password')}
                      value={values.password}
                      fullWidth
                      margin='normal'
                    />
                  </div>
                  <div className={classes.actions}>
                    <Button type='submit' className={classes.loginButton} variant='contained'>
                      {t('loginPage/login')}
                    </Button>
                    <Button
                      onClick={handleOpenRegistrationDialog}
                      component={'a'}
                      className={classes.registrationButton}
                      variant='contained'
                    >
                      {t('loginPage/register')}
                    </Button>
                    <Link component={'a'} onClick={() => setOpen((ps) => !ps)}>
                      {t('loginPage/forgotPassword')}
                    </Link>
                  </div>
                  <Collapse in={open}>
                    <div className={classes.forgotFieldsContainer}>
                      <TextField
                        type='text'
                        label={t('loginPage/enterYourEmail')}
                        onChange={handleChange('forgotUser')}
                        value={values.forgotUser}
                        fullWidth
                        margin='normal'
                      />
                      <Button variant='contained' color='primary' size='small' onClick={handlePasswordReset}>
                        {t('common/send')}
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </form>
            ) : (
              <div>
                <Tooltip title={t('app/logout')}>
                  <IconButton
                    edge='end'
                    color='inherit'
                    onClick={() => {
                      setLogoutConfirm(true);
                    }}
                  >
                    <LockIcon />
                  </IconButton>
                </Tooltip>
                <DialogConfirm
                  open={logoutConfirm}
                  onClose={() => {
                    setLogoutConfirm(false);
                  }}
                  onConfirm={() => {
                    history.push(routeUrlFactory('logout'));
                  }}
                  title={t('app/logout')}
                >
                  <div>{t('app/logoutConfirmation')}</div>
                </DialogConfirm>
              </div>
            )}
          </div>

          <div className={classes.hpContent}>
            <div className={classes.carouselWrapper}>
              <img src={`/${projectName}/homepage/homepage1.jpg`} alt='logo' />
            </div>

            <div className={classes.imgSection}>
              {leftImageUnderCarousel &&
                leftImageUnderCarousel.path &&
                (leftImageUnderCarousel.url ? (
                  <Link key={leftImageUnderCarousel.path} href={leftImageUnderCarousel.url}>
                    <SimpleImage
                      src={`${FTP_URL}${leftImageUnderCarousel.path}`}
                      title={leftImageUnderCarousel.title}
                      alt={leftImageUnderCarousel.title ?? ''}
                    />
                  </Link>
                ) : (
                  <SimpleImage
                    key={leftImageUnderCarousel.path}
                    src={`${FTP_URL}${leftImageUnderCarousel.path}`}
                    title={leftImageUnderCarousel.title}
                    alt={leftImageUnderCarousel.title ?? ''}
                  />
                ))}

              {rightImageUnderCarousel &&
                rightImageUnderCarousel.path &&
                (rightImageUnderCarousel.url ? (
                  <Link key={rightImageUnderCarousel.path} href={rightImageUnderCarousel.url}>
                    <SimpleImage
                      src={`${FTP_URL}${rightImageUnderCarousel.path}`}
                      title={rightImageUnderCarousel.title}
                      alt={rightImageUnderCarousel.title ?? ''}
                    />
                  </Link>
                ) : (
                  <SimpleImage
                    key={rightImageUnderCarousel.path}
                    src={`${FTP_URL}${rightImageUnderCarousel.path}`}
                    title={rightImageUnderCarousel.title}
                    alt={rightImageUnderCarousel.title ?? ''}
                  />
                ))}
            </div>
          </div>
        </div>
      </Container>
      <LoginRegistrationDialog openDialog={openRegistration} onClose={handleCloseRegistrationDialog} />
    </>
  );
};

HomePageNew.displayName = 'HomePageNew';

export default wrapLayout(HomePageNew, InfoLayout);
