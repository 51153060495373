import { atom } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { ShoppingCartProduct } from '../shoppingCart';
import { ShoppingCustomerContact } from '../shopping';

export function getPayments(
  apiClient: ApiClient,
  customer: ShoppingCustomerContact,
  cartId: string,
): Promise<MoleculerListResult<any>> {
  return apiClient.post('/v1/payment/list', { cartId, customer, getAllPayments: true /*, belowLimit*/ });
}

export const paymentsState = GlobalStateRegister.register(
  atom<Array<ShoppingCartProduct> | []>({
    key: 'payments',
    default: [],
  }),
);
