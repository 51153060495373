import { isDate } from 'date-fns';
import React from 'react';
import { ProductStockQuantity, ProductTaxonomies } from '../../@sprinx/knihovka-types';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export function useProductStockQuantity(
  product: ProductStockQuantityProduct,
): [onStockQty: number, notAvailable: boolean, timing?: string | number] {
  return React.useMemo(() => {
    const wh = product.stockQuantity?.detail?.[0];
    const quantity = wh?.quantity ?? 0;

    const getMinDate = (val: (string | number)[]) => {
      return val.reduce((acc, d) => {
        if (isDate(d) && d !== undefined) {
          return acc !== undefined && isDate(acc) && new Date(acc) < new Date(d) ? acc : d;
        }

        return acc;
      });
    };
    const timing = wh && wh.timing && wh.state === 'ordered' ? getMinDate(wh.timing) : undefined;

    return [quantity, quantity <= 0, timing];
  }, [product]);
}

export function useProductAvailability(product: ProductStockQuantityProduct): boolean {
  // TODO: add logic ?
  const [onStockQty] = useProductStockQuantity(product);

  return onStockQty > 0;
}

export function useProductStockQuantityLabel(product: ProductStockQuantityProduct): string {
  const t = useTranslateWithFallback();
  const [quantity] = useProductStockQuantity(product);

  const labelForOutOfStock = product.deliveryDateInfo
    ? product.deliveryDateInfo === 'na dotaz'
      ? t('app/products/stockQuantityInfo/onRequest')
      : product.deliveryDateInfo
    : t('app/products/stockQuantityInfo/notOnStock');

  if (quantity <= 0) return labelForOutOfStock;
  if (quantity <= 50) return t('app/products/stockQuantityInfo/pcs', { qty: quantity });
  if (quantity <= 100) return t('app/products/stockQuantityInfo/pcs50to100');
  if (quantity <= 500) return t('app/products/stockQuantityInfo/pcs100to500');
  return t('app/products/stockQuantityInfo/pcsOver500');
}

export interface ProductStockQuantityProduct {
  deliveryDateInfo?: string;
  quantityStep?: number;
  stockQuantity?: ProductStockQuantity;
  taxonomies?: ProductTaxonomies;
}
