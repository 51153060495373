import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PercentFieldPreview from '@sprinx/react-mui-fields/PercentFieldPreview';
import TextFieldPreview from '@sprinx/react-mui-fields/TextFieldPreview';
import NumberFieldPreview from '@sprinx/react-mui-fields/NumberFieldPreview';
import clsx from 'clsx';
import React from 'react';
import invariant from 'tiny-invariant';
import { ShoppingCartSubject } from '../../../api/shoppingCart';
import { useProductStockQuantities } from '../../../components/ProductStockProvider/ProductStockProvider';
import { showStockAvailability } from '../../../config';
import ProductStockQuantityInfo from '../../../components/ProductStockQuantityInfo';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface CartSummarySubjectListItemInlineParametersProps
  extends StandardDivProps<CartSummarySubjectListItemInlineParametersClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemInlineParametersClassKey =
  | 'root'
  | 'field'
  | 'code'
  | 'minOrderableQuantity'
  | 'unit'
  | 'vat'
  | 'stock';

const themeSettings = { name: 'CartSummarySubjectListItemInlineParameters' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummarySubjectListItemInlineParametersClassKey, {}>({
      root: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(1.5),
        },
      },
      field: {
        width: 'auto',
        [theme.breakpoints.down('md')]: {
          minWidth: 258,
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          margin: 0,
          '& div': {
            padding: theme.spacing(0.25, 0),
          },
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: 258,
          alignItems: 'center',
          margin: 0,
          '& div': {
            padding: 0,
          },
        },
      },
      code: {
        width: 80,
        [theme.breakpoints.up('xl')]: {
          width: 124,
        },
      },
      minOrderableQuantity: {
        width: 70,
        textAlign: 'center',
        [theme.breakpoints.up('xl')]: {
          width: 124,
        },
      },
      unit: {
        width: 59,
        [theme.breakpoints.up('xl')]: {
          width: 124,
        },
      },
      vat: {
        width: 36,
        [theme.breakpoints.up('xl')]: {
          width: 124,
        },
      },
      stock: {
        textAlign: 'right',
        flexGrow: 2,
      },
    }),
  themeSettings,
);

const CartSummarySubjectListItemInlineParameters = React.forwardRef<
  HTMLDivElement,
  CartSummarySubjectListItemInlineParametersProps
>(({ className, classes: pClasses, subject }, ref) => {
  const classes = useStyles({ classes: pClasses });
  const quantities = useProductStockQuantities();
  const t = useTranslateWithFallback();

  invariant(typeof subject.product !== 'string');

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      <TextFieldPreview
        className={clsx(classes.field, classes.code)}
        label={t('common/code')}
        value={subject.product.sku || ''}
        size='small'
      />
      <NumberFieldPreview
        label={t('productPage/minOrderableQuantity')}
        value={subject.product.quantityStep}
        size='small'
        decimalPrecision={0}
        alignLabel
        className={clsx(classes.field, classes.minOrderableQuantity)}
      />
      <TextFieldPreview
        label={t('common/unit')}
        value={subject.product.unit}
        size='small'
        className={clsx(classes.field, classes.unit)}
        alignLabel
      />
      {subject.taxInfo && (
        <PercentFieldPreview
          className={clsx(classes.field)}
          label={t('common/vat')}
          value={subject.taxInfo.value}
          size='small'
          align='right'
        />
      )}
      {showStockAvailability && (
        <div className={classes.stock}>
          <ProductStockQuantityInfo product={{ stockQuantity: quantities && quantities[subject?.product?.id] }} />
        </div>
      )}
    </div>
  );
});

CartSummarySubjectListItemInlineParameters.displayName = 'CartSummarySubjectListItemInlineParameters';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemInlineParameters);
