import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/EditTwoTone';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BasketIcon from '@material-ui/icons/ShoppingBasketOutlined';
import dateFormatter from '@sprinx/formatting/dateFormatter';
import ProductImage from '@sprinx/react-com/ProductImage';
import IconButtonDelete from '@sprinx/react-mui-components/IconButtonDelete';
import IconButtonWithTooltip from '@sprinx/react-mui-components/IconButtonWithTooltip';
import { withStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  ShoppingListItem,
  shoppingListItemToRecord,
  shoppingListsRenameRequestState,
  shoppingListsSelectRequestState,
  useShoppingListDeleteItem,
  useShoppingListsInsertToCart,
  useShoppingListsUpdateItem,
} from '../../../api/shoppingLists';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import ShoppingListItemExpanded from './ShoppingListItemExpanded';

const ShoppingListItem = ({
  classes = {},
  className,
  enabeleEditation,
  numberOfThumbnails = 3,
  shoppingList,
  ...rest
}: Omit<ListItemProps, 'classes' | 'button'> & {
  classes?: Record<string, string>;
  className?: string;
  enabeleEditation: boolean;
  numberOfThumbnails?: number;
  shoppingList: ShoppingListItem;
}) => {
  const t = useTranslateWithFallback();
  const [open, setOpen] = React.useState(false);

  const { name, subjects = [], dateOfCreation } = shoppingList;

  const [thumbProds, moreIdent, collapsable] = React.useMemo(
    () => [subjects.slice(0, numberOfThumbnails), subjects.length - numberOfThumbnails, subjects.length > 0],
    [numberOfThumbnails, subjects],
  );

  const deleteShoppingListsItem = useShoppingListDeleteItem(shoppingList.id);
  const updateShoppingListItem = useShoppingListsUpdateItem();
  const requestShoppingListItemRename = useSetRecoilState(shoppingListsRenameRequestState);
  const setOpenSelectShoppingListDialog = useSetRecoilState(shoppingListsSelectRequestState);

  const handleInsertToCart = useShoppingListsInsertToCart(shoppingList);

  const handleRemoveSubject = React.useCallback(
    (idx: number): void => {
      const nextSubjects = [...shoppingList.subjects.slice(0, idx), ...shoppingList.subjects.slice(idx + 1)];
      const nextEntity = shoppingListItemToRecord({
        ...shoppingList,
        subjects: nextSubjects,
      });
      updateShoppingListItem(nextEntity);
    },
    [shoppingList, updateShoppingListItem],
  );

  return (
    <ListItem className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.content, className)}>
        <ListItemAvatar>
          <Badge badgeContent={subjects.length} color='secondary' overlap='circle'>
            <Avatar>
              <BasketIcon />
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={dateFormatter(dateOfCreation)} />
        {collapsable && (
          <IconButton onClick={() => setOpen((p) => !p)}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
        )}
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit className={clsx(classes.contentCollapsed, className)}>
        <ShoppingListItemExpanded
          subjects={subjects}
          enabeleEditation={enabeleEditation}
          onRemoveSubject={handleRemoveSubject}
        />
      </Collapse>
      <div className={clsx(classes.content, className)}>
        {!open && collapsable && (
          <div className={clsx(classes.teaserImageContainer, className)}>
            {thumbProds.map((i, index) => (
              <ProductImage
                className={classes.thumbnail}
                src={i.product.image}
                alt={i.product.name}
                key={index.toString()}
                size='mini'
                shape='square'
              />
            ))}
            {moreIdent > 0 && <span className={classes.moreIdent}>{`+ ${moreIdent}`}</span>}
          </div>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color='default'
              variant='outlined'
              size='small'
              onClick={() =>
                handleInsertToCart().then(() => {
                  setOpenSelectShoppingListDialog(false);
                })
              }
            >
              {t('customerProfileOrderDetailPage/actions/import')}
            </Button>
            {enabeleEditation && (
              <>
                <IconButtonWithTooltip
                  onClick={() => requestShoppingListItemRename(shoppingListItemToRecord(shoppingList))}
                  label={t('customerProfileOrderDetailPage/actions/rename')}
                >
                  <EditIcon />
                </IconButtonWithTooltip>
                <IconButtonDelete onClick={() => deleteShoppingListsItem()} />
              </>
            )}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

const ShoppingListItemStyled = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(1, 2),
  },
  teaserImageContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      maxWidth: 30,
      margin: theme.spacing(0, 0.5),
    },
    '& > img:last-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  contentCollapsed: {
    width: '100%',
  },
  thumbnail: {
    margin: theme.spacing(0, 0.25),
  },
  moreIdent: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
}))(ShoppingListItem) as typeof ShoppingListItem;

export default ShoppingListItemStyled;
