import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NavigateBackIcon from '@material-ui/icons/NavigateBeforeTwoTone';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import Page from '../../@sprinx/react-web/Page';
import PageHeadline from '../../@sprinx/react-web/PageHeadline';
import DialogConfirm from '../../@sprinx/react-mui-components/DialogConfirm';
import { apiClientState, authUserState, currencyState, localeState } from '../../api/appState';
import { cartsQuery } from '../../api/cart/cart';
import useCancelOrderForApprovalHandler from '../../api/orders/useCancelOrderForApprovalHandler';
import { useOrderSaveFromCartHandler } from '../../api/orders/useOrderSaveHandler';
import { shoppingSelectedDeliveryPlaceState } from '../../api/shopping';
import { ShoppingCart } from '../../api/shoppingCart';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import ProductPriceElement from '../../components/ProductPriceElement';
import ProductStockProvider from '../../components/ProductStockProvider/ProductStockProvider';
import ShoppingListsActionsHolder from '../../components/ShoppingListsActionsHolder';
import CartSummaryTotals from '../CartSummaryPage/components/CartSummaryTotals';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import CartApprovalSubjectsList from './components/CartApprovalSubjectsList';
import ShoppingListsDialogSavePurpose from './components/ShoppingListsDialogSavePurpose';

export type CartApprovalPageLayoutProps = StandardDivProps<CartApprovalPageLayoutClassKey> & { cartId: string };
export type CartApprovalPageLayoutClassKey =
  | 'root'
  | 'navigation'
  | 'navigationAsideActions'
  | 'informationDiv'
  | 'value'
  | 'heading'
  | 'additionalInfo'
  | 'totalsContainer'
  | 'alert'
  | 'cancelButtonDiv'
  | 'createButtonDiv'
  | 'catalogueButton'
  | 'deliveryDate';

const themeSettings = { name: 'CartApprovalPageLayout' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartApprovalPageLayoutClassKey, {}>({
      root: {},
      navigation: {
        display: 'flex',
      },
      navigationAsideActions: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      informationDiv: {
        fontSize: '16px',
        margin: theme.spacing(2, 0),
        '@media(min-width: 450px)': {
          display: 'flex',
        },
      },
      value: {
        padding: theme.spacing(0, 0, 0, 2),
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        flexDirection: 'column',
      },
      heading: {
        margin: theme.spacing(2, 0),
        fontWeight: 'bold',
      },
      additionalInfo: {
        opacity: 0.6,
        fontSize: '12px',
      },
      totalsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        '@media(max-width: 530px)': {
          'flex-direction': 'column',
          'align-items': 'center',
        },
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
        '& > div': {
          width: theme.spacing(30),
          '& > button': {
            width: '100%',
            marginBottom: theme.spacing(2),
          },
        },
      },
      alert: {
        width: '100%',
        ...theme.typography.body2,
        paddingLeft: '10px',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
      cancelButtonDiv: {
        '@media(min-width: 531px)': {
          'margin-left': theme.spacing(1),
        },
      },
      createButtonDiv: {
        '@media(min-width: 531px)': {
          'margin-right': theme.spacing(1),
        },
      },
      catalogueButton: {
        minWidth: '230px',
      },
      deliveryDate: {
        display: 'inline-flex',
        flexFlow: 'column',
        justifyContent: 'flex-start',
        width: '270px',
        margin: theme.spacing(2, 0),
      },
    }),
  themeSettings,
);

const CartApprovalPageLayout = React.forwardRef<HTMLDivElement, CartApprovalPageLayoutProps>(
  ({ className, classes: pClasses, cartId }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const routeUrlFactory = useRouteUrlFactory();
    // const [extraValues, setExtraValues] = React.useState<CartExtraValues>({
    //   noteForSeller: '',
    //   orderNumberExternal: '',
    // });

    const [cart, setCart] = useState<ShoppingCart | undefined>(undefined);
    const [userLimit, setUserLimit] = useState<number | undefined>(undefined);
    const [hasSupervisor, setHasSupervisor] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const apiClient = useRecoilValue(apiClientState);
    const user = useRecoilValue(authUserState);
    // const currency = useRecoilValue(currencyState);
    // const isDeliveryPlaceSelected = useRecoilValue(shoppingIsDeliveryPlaceSelectedSelector);
    // const hasPalettes = useRecoilValue(shoppingCartHasPalettesSelector);
    // const allPalettes = useRecoilValue(shoppingCartAreAllPalettesSelector);
    const deliveryPlace = useRecoilValue(shoppingSelectedDeliveryPlaceState);
    const [saveOrderFromCart] = useOrderSaveFromCartHandler();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [shippingName, setShippingName] = React.useState<string>('');
    const [showConfirmSave, setShowConfirmSave] = React.useState<boolean>(false);
    // const auth = useRecoilValue(authState);
    const showMessage = useSetRecoilState(enqueueSnackbarMessage);
    const [showCancelForm, setShowCancelForm] = React.useState<boolean>(false);
    const [cancelOrderForApproval] = useCancelOrderForApprovalHandler();
    // const isMasterScope = useRecoilValue(isMasterScopeState);
    const locale = useRecoilValue(localeState);
    const currency = useRecoilValue(currencyState);
    const handleReload = useResetRecoilState(cartsQuery);
    // const [requestedDeliveryDate, setRequestedDeliveryDate] = React.useState<Date | null>(null);

    // const handleSetExtraValues = React.useCallback((values: Partial<CartExtraValues>) => {
    //   setExtraValues((prev) => ({ ...prev, ...values }));
    // }, []);
    const t = useTranslateWithFallback();
    const handleSaveOrder = React.useCallback(() => {
      // eslint-disable-next-line no-alert
      // setDisabled(true); //Disable in DialogConfirm to prevent multiple calls
      saveOrderFromCart({
        expectedDeliveryDate: new Date(),
        cartId: cart?.id,
        // noteForSeller: extraValues.noteForSeller,
        extra: {
          ...(deliveryPlace || {}).extra,
          // externalOrderNumber: extraValues.orderNumberExternal,
          requestedDeliveryDate: null,
        },
      })
        .catch((err) => {
          setShowConfirmSave(false);
          handleReload();
          showMessage({ message: `${err}`, variant: 'error' });
        })
        .then((createdOrder) => {
          setShowConfirmSave(false);
          handleReload();
          if (createdOrder.state === 'waiting-for-approval') {
            showMessage({ message: 'Objednávka schválena a poslána dál', variant: 'success' });
          }
          const returnMessage = createdOrder as ResponseMessage;
          if (returnMessage && returnMessage.status == 'error') {
            showMessage({ message: `${returnMessage.msg}`, variant: 'error' });
          } else {
            if (createdOrder.state === 'waiting-for-approval') {
              showMessage({ message: 'Objednávka schválena a poslána dál', variant: 'success' });
            } else {
              history.push(routeUrlFactory('orderThankYou'), { order: createdOrder });
              // resetCart(false);
            }
          }
        });
    }, [cart?.id, deliveryPlace, handleReload, history, routeUrlFactory, saveOrderFromCart, showMessage]);

    const handleConfirmCancel = React.useCallback(
      ({ purpose }) => {
        setDisabled(true);
        cancelOrderForApproval({ purpose, cartId })
          .catch((err) => {
            setShowCancelForm(false);
            showMessage({ message: `${err}`, variant: 'error' });
          })
          .then((cart) => {
            setShowCancelForm(false);
            handleReload();
            const returnMessage = cart as ResponseMessage;
            if (returnMessage && returnMessage.status == 'error') {
              showMessage({ message: `${returnMessage.msg}`, variant: 'error' });
            } else {
              history.push(routeUrlFactory('orderDisapprovalSending'), { cart });
            }
          });
      },
      [cancelOrderForApproval, cartId, showMessage, handleReload, history, routeUrlFactory],
    );

    // const userIsImpersonated =
    //   auth && auth.tokenPayload ? ((auth.tokenPayload?.sub || '') as any).startsWith('IMPERSONATED') : false;

    const cartIsClosed = !cart || ['approved', 'canceled'].includes(cart?.state);
    const createOrder = userLimit === null || (cart && userLimit && userLimit >= cart?.cartContent?.total);

    // console.log(cart?.owner, user);

    const orderSubmitDisabled =
      disabled ||
      cartIsClosed ||
      cart?.owner === user?.id ||
      (!hasSupervisor && !createOrder) ||
      !(
        (
          cart &&
          cart.cartContent?.subjects?.length > 0 &&
          (cart.extra?.orderProps?.invoiceContact?.id || cart.extra?.orderProps?.invoiceContact)
        )
        // typeof cart.cartContent?.shipping?.product === 'object' // no more object, zmena v knihovnicce
        // (!hasPalettes || allPalettes)
      );

    // const orderTotalLimit = useRecoilValue(customerOrderTotalLimitState);
    // const orderTotalInLimit =
    //   typeof cart?.cartContent.total === 'number' &&
    //   (typeof orderTotalLimit === 'undefined' || cart?.cartContent.total < orderTotalLimit);

    useEffect(() => {
      if (cartId) {
        setLoading(true);
        apiClient
          .get<{ cart: ShoppingCart; hasSupervisor: boolean; userLimit: number }, { cartId: string }>(
            '/v1/shopping-cart/get-for-approval',
            {
              cartId,
            },
          )
          .then(({ cart, userLimit, hasSupervisor }) => {
            setUserLimit(userLimit);
            setCart(cart);
            setHasSupervisor(hasSupervisor);
            setDisabled(false);
            setLoading(false);
          });
      }
    }, [cartId, apiClient]);

    useEffect(() => {
      const shippingNameObj =
        typeof cart?.cartContent.shipping.product === 'object'
          ? cart?.cartContent.shipping.product.name.find((x) => x.language === locale)
          : undefined;
      setShippingName(shippingNameObj?.text ? shippingNameObj.text : '');
    }, [cart?.cartContent.shipping.product, locale]);

    if (cart == null) {
      return (
        <Page ref={ref} className={clsx(classes.root, className)} pageTitle={t('cartApprovalPage/pageTitle')}>
          <PageHeadline>{t('cartApprovalPage/pageTitle')}</PageHeadline>
          {loading ? (
            <div>{t('common/loading')}</div>
          ) : (
            <>
              <div className={classes.informationDiv}>{t('cartApprovalPage/noCartFound')}</div>
              <div className={classes.navigation}>
                <Button
                  component={RouterLink}
                  to={routeUrlFactory('catalogue')}
                  color='default'
                  startIcon={<NavigateBackIcon />}
                  variant='outlined'
                  className={classes.catalogueButton}
                >
                  {t('cartApprovalPage/continueShopping')}
                </Button>
              </div>
            </>
          )}
        </Page>
      );
    }

    return (
      <Page ref={ref} className={clsx(classes.root, className)} pageTitle={t('cartApprovalPage/pageTitle')}>
        <ProductStockProvider>
          <PageHeadline>{t('cartApprovalPage/pageTitle')}</PageHeadline>
          <div className={classes.navigation}>
            <Button
              component={RouterLink}
              to={routeUrlFactory('catalogue')}
              color='default'
              startIcon={<NavigateBackIcon />}
              variant='outlined'
              className={classes.catalogueButton}
            >
              {t('cartApprovalPage/continueShopping')}
            </Button>
          </div>
          <div className={classes.informationDiv}>
            <div className={classes.heading}>{t('app/pickDeliveryPlace/label')}: </div>
            {cart && (
              <div className={classes.value}>
                {cart.extra.orderProps.invoiceContact.name}
                <span className={classes.additionalInfo}>{cart.extra.orderProps.postAddress}</span>
              </div>
            )}
          </div>
          <div className={classes.informationDiv}>
            <div className={classes.heading}>{t('app/pickShipping/label')}: </div>
            {shippingName && cart && (
              <span className={classes.value}>
                {shippingName}
                <span className={classes.additionalInfo}>
                  <ProductPriceElement
                    value={cart.cartContent.shipping.price}
                    currency={currency}
                    labelEnd={t('common/withoutTax')}
                  />
                </span>
              </span>
            )}
          </div>
          <CartApprovalSubjectsList cart={cart} />
          <CartSummaryTotals cart={cart} />
          {/* <CartSummaryExtraFields values={extraValues} onChange={handleSetExtraValues} /> */}

          {/* <div className={classes.deliveryDate}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
              <DatePicker
                label='Požadované datum dodávky'
                value={requestedDeliveryDate}
                format={'dd.MM.yyyy'}
                clearable
                minDate={new Date()}
                maxDate={addDays(new Date(), 365)}
                onChange={setRequestedDeliveryDate}
                helperText='Vyplňte dle aktuálních termínů závozu'
              />
            </MuiPickersUtilsProvider>
          </div> */}
          {cart?.extra.orderProps.noteForSeller && cart && (
            <div className={classes.informationDiv}>
              <div className={classes.heading}>{t('common/supplierNote')}: </div>
              <span className={classes.value}>{cart.extra.orderProps.noteForSeller}</span>
            </div>
          )}
          <div className={classes.totalsContainer}>
            <div className={classes.createButtonDiv}>
              <Button
                color='secondary'
                variant='contained'
                size='large'
                onClick={() => {
                  setShowConfirmSave(true);
                }}
                disabled={orderSubmitDisabled}
              >
                {t('common/approveOrder')}
              </Button>
            </div>
            <div className={classes.cancelButtonDiv}>
              <Button
                color='secondary'
                variant='contained'
                size='large'
                onClick={() => {
                  setShowCancelForm(true);
                }}
                disabled={cartIsClosed}
              >
                {t('common/rejectOrder')}
              </Button>
            </div>
          </div>
          <ShoppingListsActionsHolder />

          <DialogConfirm
            open={showConfirmSave}
            onClose={() => {
              setShowConfirmSave(false);
            }}
            onConfirm={() => {
              if (!disabled) {
                setDisabled(true);
                handleSaveOrder();
              }
            }}
            title={t('common/newOrderConfirmation')}
          >
            <div>{t('cartApprovalPage/areYouSureToSend')}</div>
          </DialogConfirm>
          <ShoppingListsDialogSavePurpose
            initialValues={{ purpose: '' }}
            open={showCancelForm}
            onClose={() => {
              setShowCancelForm(false);
            }}
            onSave={handleConfirmCancel}
          />
        </ProductStockProvider>
      </Page>
    );
  },
);

export interface ResponseMessage {
  msg: string;
  status: string;
}

CartApprovalPageLayout.displayName = 'CartApprovalPageLayout';

export default withThemeProps(themeSettings)(CartApprovalPageLayout);
