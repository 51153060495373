import { TranslationDictionary } from '@sprinx/react-globalize/types';
import { useCallback } from 'react';
import { atom, DefaultValue, selector, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import invariant from 'tiny-invariant';
import { Optional } from 'utility-types';
import { ProductImage } from '../@sprinx/knihovka-types';
import { ApiClient } from '../@sprinx/react-after-razzle';
import useTranslateWithFallback from '../hooks/useTranslateWithFallback';
import { apiClientState, currencyState, pricesTypeState } from './appState';
import { CatalogueProduct } from './products/catalogue';
import {
  justifyQuantity,
  productQuantityLimitsDefaults,
  shoppingCartState,
  ShoppingCartUpdateCartDataItemParams,
  useUpdateCart,
} from './shoppingCart';
import { enqueueSnackbarMessage } from './snackbars';

export const shoppingListsCreateRequestState = atom<boolean>({
  key: 'shoppingListsCreateRequest',
  default: false,
});

export const shoppingListsRenameRequestState = atom<ShoppingListItemRecord | undefined>({
  key: 'shoppingListsRenameRequest',
  default: undefined,
});

export const shoppingListsSelectRequestState = atom<boolean>({
  key: 'shoppingListsSelectRequest',
  default: false,
});

export const shoppingListsListQueryReloadTrigger = atom<number>({
  key: 'shoppingListsListQueryReloadTrigger',
  default: 1,
});

export const shoppingListsListQuery = selector<ShoppingListItem[]>({
  key: 'shoppingListsListQuery',
  get: ({ get }) => {
    const apiClient = get(apiClientState);
    get(shoppingListsListQueryReloadTrigger);
    return shoppingListsGetList(apiClient);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(shoppingListsListQueryReloadTrigger, (n) => n + 1);
    }
  },
});

export const useShoppingListsInitialShoppingListItem = (): Optional<ShoppingListItemRecord, 'id'> | undefined => {
  const cart = useRecoilValue(shoppingCartState);
  if (!cart) return undefined;

  return {
    name: '',
    dateOfCreation: new Date(),
    subjects: cart.cartContent.subjects.map((s) => ({
      id: s.id,
      product: typeof s.product === 'string' ? s.product : s.product.id,
      quantity: s.quantity,
    })),
  };
};

export const shoppingListItemToRecord = (item: ShoppingListItem): ShoppingListItemRecord => {
  return {
    ...item,
    subjects: item.subjects.map((i) => ({
      ...i,
      product: i.product.id,
    })),
  };
};

export const useShoppingListsCreateItem = (): ((entity: Optional<ShoppingListItemRecord, 'id'>) => Promise<any>) => {
  const apiClient = useRecoilValue(apiClientState);
  const reloadLists = useResetRecoilState(shoppingListsListQuery);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const t = useTranslateWithFallback();

  return useCallback(
    (entity: Optional<ShoppingListItemRecord, 'id'>): Promise<any> =>
      apiClient
        .post('/v1/shopping-lists', {
          ...entity,
          dateOfCreation: new Date(),
        })
        .then((x) => {
          reloadLists();
          showMessage({
            message: t('customerProfileShoppingListsPage/shoppingListCreate', {
              name: (x as ShoppingListItemRecord).name,
            }),
            variant: 'success',
          });
          return x;
        }),
    [apiClient, reloadLists, showMessage, t],
  );
};

export const useShoppingListsUpdateItem = (): ((entity: ShoppingListItemRecord) => Promise<any>) => {
  const apiClient = useRecoilValue(apiClientState);
  const reloadLists = useResetRecoilState(shoppingListsListQuery);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const t = useTranslateWithFallback();

  return useCallback(
    (entity: ShoppingListItemRecord): Promise<any> =>
      apiClient.put('/v1/shopping-lists/:id', entity).then((x) => {
        reloadLists();
        showMessage({
          message: t('customerProfileShoppingListsPage/shoppingListUpdated', {
            name: (x as ShoppingListItemRecord).name,
          }),
          variant: 'success',
        });
        return x;
      }),
    [apiClient, reloadLists, showMessage, t],
  );
};

export const useShoppingListDeleteItem = (id: string): (() => Promise<any>) => {
  const apiClient = useRecoilValue(apiClientState);
  const reloadLists = useResetRecoilState(shoppingListsListQuery);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const t = useTranslateWithFallback();

  return useCallback((): Promise<any> => {
    return apiClient.delete('/v1/shopping-lists/:id', { id }).then((x) => {
      reloadLists();
      showMessage({
        message: t('customerProfileShoppingListsPage/shoppingListDeleted', {
          name: (x as ShoppingListItemRecord).name,
        }),
        variant: 'warning',
      });
      return x;
    });
  }, [apiClient, id, reloadLists, showMessage, t]);
};

export const useShoppingListsInsertToCart = (shoppingList: ShoppingListItem): (() => Promise<any>) => {
  const apiClient = useRecoilValue(apiClientState);
  const currency = useRecoilValue(currencyState);
  const pricesType = useRecoilValue(pricesTypeState);
  const cart = useRecoilValue(shoppingCartState);

  const updateCart = useUpdateCart();
  return useCallback<() => Promise<any>>(() => {
    invariant(cart, 'Cart not initialized!');

    const productIds = shoppingList.subjects.map((i) => i.product.id);

    return apiClient
      .post<
        { products: CatalogueProduct[] },
        { currency: string; pricesType: 'B2B' | 'B2C' | 'B2BForeign' | undefined; products: string[] }
      >('/v1/catalogue/presents', {
        products: productIds,
        currency,
        pricesType,
      })
      .then(
        (res) =>
          res.products
            .map<ShoppingCartUpdateCartDataItemParams | undefined>((p) => {
              const found = shoppingList.subjects.find((s) => s.product.id === p.id);
              const inShoppingCart = cart.cartContent.subjects.find(
                (c) =>
                  (typeof c.product === 'string' && c.product === p.id) ||
                  (typeof c.product !== 'string' && c.product.id === p.id),
              );

              const productLimits = productQuantityLimitsDefaults(p);

              return found
                ? {
                    product: { ...p, ...productLimits },
                    quantity: justifyQuantity(productLimits, found.quantity + (inShoppingCart?.quantity || 0)),
                  }
                : undefined;
            })
            .filter(Boolean) as ShoppingCartUpdateCartDataItemParams[],
      )
      .then((res) => {
        updateCart(res);
      });
  }, [apiClient, cart, currency, pricesType, shoppingList.subjects, updateCart]);
};

export const shoppingListsGetList = (apiClient: ApiClient): Promise<ShoppingListItem[]> => {
  return apiClient.get<ShoppingListItem[], {}>('/v1/shopping-lists/full-list', {});
};

export interface ShoppingListItem {
  dateOfCreation: string | Date;
  id: string;
  name: string;
  subjects: ShoppingListItemSubject[];
}

export interface ShoppingListItemSubject {
  id: string;
  product: ShoppingListItemSubjectProduct;
  quantity: number;
  unit?: string;
}

export interface ShoppingListItemSubjectProduct {
  id: string;
  image?: ProductImage;
  name: TranslationDictionary;
  sku: string;
}

export interface ShoppingListItemRecord {
  dateOfCreation: string | Date;
  id: string;
  name: string;
  subjects: {
    id: string;
    product: string;
    quantity: number;
    unit?: string;
  }[];
}
