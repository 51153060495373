import React from 'react';
import { useRecoilState } from 'recoil';
import { Optional } from 'utility-types';
import {
  ShoppingListItemRecord,
  shoppingListsCreateRequestState,
  useShoppingListsCreateItem,
  useShoppingListsInitialShoppingListItem,
} from '../../api/shoppingLists';
import ShoppingListsDialogSave from '../ShoppingListsDialogSave';

const ShoppingListsDialogCreate: React.FC = () => {
  const [open, setOpen] = useRecoilState(shoppingListsCreateRequestState);
  const createShoppingListItem = useShoppingListsCreateItem();
  const initialValues = useShoppingListsInitialShoppingListItem();

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // const handleSave = useShoppingListsCreate();
  const handleSave = React.useCallback(
    (data: Optional<ShoppingListItemRecord, 'id'>): void => {
      createShoppingListItem(data);
    },
    [createShoppingListItem],
  );

  return initialValues && open ? (
    <ShoppingListsDialogSave initialValues={initialValues} open onClose={handleClose} onSave={handleSave} />
  ) : null;
};

ShoppingListsDialogCreate.displayName = 'ShoppingListsDialogCreate';

export default ShoppingListsDialogCreate;
