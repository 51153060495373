import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ProductImage from '@sprinx/react-com/ProductImage';
import IconButtonDelete from '@sprinx/react-mui-components/IconButtonDelete';
import ListPretty from '@sprinx/react-mui-components/ListPretty';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import kebabCase from 'lodash/kebabCase';
import { ShoppingListItemSubject } from '../../../api/shoppingLists';
import { localeState } from '../../../api/appState';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import ShoppingListsListSku from './ShoppingListsListSku';

const ShoppingListItemExpanded = ({
  classes = {},
  className,
  enabeleEditation = false,
  onRemoveSubject,
  subjects,
}: {
  classes?: Record<string, string>;
  className?: string;
  enabeleEditation?: boolean;
  onRemoveSubject?: (index: number) => void;
  subjects: ShoppingListItemSubject[];
}) => {
  const t = useTranslateWithFallback();
  const routeUrlFactory = useRouteUrlFactory();

  const handleDeleteItem = React.useCallback(
    (idx) => () => {
      if (onRemoveSubject) onRemoveSubject(idx);
    },
    [onRemoveSubject],
  );

  const locale = useRecoilValue(localeState);

  return (
    <ListPretty className={clsx(classes.subjectsList, className)} disablePadding>
      {subjects.map((i, index) => (
        <ListItem key={index.toString()} divider={index !== subjects.length - 1}>
          <div className={classes.container}>
            <ListItemAvatar>
              <ProductImage src={i.product.image} alt={i.product.name} size='small' />
            </ListItemAvatar>

            <div className={classes.itemContentContent}>
              <div className={classes.itemContentName}>
                <Link
                  component={RouterLink}
                  to={routeUrlFactory('product', {
                    params: {
                      sn: kebabCase(
                        (typeof i.product.name === 'string'
                          ? i.product.name
                          : i.product.name.find((i) => i.language === locale)?.text) || '',
                      ),
                      id: i.product.id,
                    },
                  })}
                >
                  {t(i.product.name, { acceptString: true })}
                </Link>
              </div>
              <ShoppingListsListSku sku={i.product.sku} />
            </div>
          </div>
          <div className={classes.quantity}>
            {i.quantity} {i.unit || 'ks'}
          </div>
          {enabeleEditation && <IconButtonDelete onClick={handleDeleteItem(index)} />}
        </ListItem>
      ))}
    </ListPretty>
  );
};

const ShoppingListItemExpandedStyled = withStyles((theme) => ({
  item: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    flexGrow: 1,
    display: 'flex',
  },
  quantity: {
    minWidth: theme.spacing(7),
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(0.5),
  },
  itemContentContent: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
  },
  itemContentName: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
  },
}))(ShoppingListItemExpanded) as typeof ShoppingListItemExpanded;

export default ShoppingListItemExpandedStyled;
