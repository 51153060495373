import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import intFormatter from '@sprinx/formatting/intFormatter';
import numberParser from '@sprinx/formatting/numberParser';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ProductQuantityLimits } from '../../@sprinx/knihovka-types';
import { useJustifyQuantity } from '../../api/shoppingCart';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export interface QuantityFieldProps
  extends Omit<StandardDivProps<QuantityFieldClassKey>, 'onChange'>,
    Omit<ProductQuantityLimits, 'quantityDefault'> {
  autoFocus?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  leftAdornment?: React.ReactNode;
  onChange?: (quantity: number) => void;
  onKeyDown?: any;
  onKeyUp?: any;
  quantity?: number;
  rightAdornment?: React.ReactNode;
  size?: 'small' | 'normal' | 'large';
}

export type QuantityFieldClassKey =
  | 'root'
  | 'withLabel'
  | 'wrapper'
  | 'buttonContainer'
  | 'button'
  | 'input'
  | 'inputinput'
  | 'fullWidth'
  | 'disabled'
  | 'sizeSmall'
  | 'sizeNormal'
  | 'sizeLarge'
  | 'rightAdornment'
  | 'leftAdornment';

const themeSettings = { name: 'QuantityField' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<QuantityFieldClassKey, {}>({
      root: {
        width: theme.spacing(21),
      },
      withLabel: {
        paddingTop: theme.spacing(2.5),
      },
      wrapper: {
        width: '100%',
        fontSize: 3,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        flexDirection: 'row',
      },
      buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      button: {
        flex: 0,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
      input: {
        padding: theme.spacing(0.5),
        justifyItems: 'center',
        maxWidth: theme.spacing(9),
      },
      inputinput: {
        textAlign: 'center',
        minWidth: 25,
      },
      fullWidth: {
        width: '100%',
      },
      disabled: {},
      sizeSmall: {
        fontSize: theme.typography.pxToRem(13),
      },
      sizeNormal: {
        fontSize: theme.typography.button.fontSize,
      },
      sizeLarge: {
        fontSize: theme.typography.pxToRem(15),
      },
      rightAdornment: {},
      leftAdornment: {},
    }),
  themeSettings,
);

const QuantityField = React.forwardRef<HTMLDivElement, QuantityFieldProps>(
  (
    {
      autoFocus,
      className,
      classes: pClasses,
      disabled = false,
      fullWidth = false,
      helperText,
      label,
      leftAdornment,
      onChange,
      quantity,
      // quantityDefault,
      quantityStep,
      quantityStepRounding,
      quantityMin,
      quantityMax,
      rightAdornment,
      size = 'normal',
      onKeyDown,
      onKeyUp,
    },
    ref,
  ) => {
    const classes = useStyles({ classes: pClasses });

    const timer = React.useRef<NodeJS.Timeout | undefined>();
    const t = useTranslateWithFallback();
    const [statusText, setStatusText] = React.useState<string | undefined>(undefined);
    const [inputStringValue, setInputValueState] = React.useState<string | undefined>(
      quantity === undefined ? undefined : intFormatter(quantity),
    );
    const setInputNumValue = React.useCallback((n: number | undefined) => {
      setInputValueState(n === undefined ? undefined : intFormatter(n));
    }, []);

    const justifyQuantity = useJustifyQuantity({
      quantityStep,
      quantityStepRounding,
      quantityMin,
      quantityMax,
    });

    const displayStatusText = React.useCallback(
      (pDisplay) => {
        if (timer.current) clearTimeout(timer.current);
        if (pDisplay) {
          setStatusText(t('shoppingCart/quantityField/quantityRounded'));
          timer.current = setTimeout(() => {
            setStatusText(undefined);
          }, 5000);
        } else {
          setStatusText(undefined);
        }
      },
      [t],
    );

    React.useEffect(() => {
      const adjusted = justifyQuantity(quantity);
      setInputNumValue(adjusted);
      // displayStatusText(quantity !== adjusted);
    }, [justifyQuantity, quantity, setInputNumValue]);

    const handleChange = React.useCallback(
      (nextQuantity: number) => {
        if (onChange && quantity !== nextQuantity) onChange(nextQuantity);
      },
      [onChange, quantity],
    );

    const handleButtonClick = (dir: -1 | 0 | 1) => () => {
      handleChange(justifyQuantity((quantity || 0) + dir * quantityStep));
      displayStatusText(false);
    };

    const handleInputBlur = React.useCallback(
      (evnt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueWithoutSpaces = evnt.target.value.replace(/\s+/g, '');
        const value = numberParser(valueWithoutSpaces);
        if (!Number.isNaN(value)) {
          const adjusted = justifyQuantity(value);
          handleChange(adjusted);
          setInputNumValue(adjusted);
          displayStatusText(value !== adjusted);
        }
      },
      [displayStatusText, handleChange, justifyQuantity, setInputNumValue],
    );

    return (
      <FormControl
        ref={ref}
        className={clsx(
          classes.root,
          { [classes.withLabel]: label !== undefined, [classes.fullWidth]: fullWidth, [classes.disabled]: disabled },
          className,
        )}
      >
        {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
        <div className={classes.wrapper}>
          <div className={classes.leftAdornment}>{leftAdornment}</div>
          <div className={classes.buttonContainer}>
            <ButtonBase
              data-testid='quantity-field-minus'
              className={clsx(classes.button, classes[`size${capitalize(size)}`])}
              disabled={disabled}
              onClick={handleButtonClick(-1)}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            >
              <RemoveIcon color='action' fontSize='inherit' />
            </ButtonBase>
            <Input
              data-testid='quantity-field-value'
              inputProps={{ 'data-testid': 'quantity-content-input' }}
              className={classes.input}
              classes={{
                input: clsx(classes.inputinput, classes[`size${capitalize(size)}`]),
              }}
              value={inputStringValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                // eslint-disable-next-line no-alert
                setInputValueState(e.target.value);
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              disabled={disabled}
              onBlur={handleInputBlur}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            />
            <ButtonBase
              data-testid='quantity-field-plus'
              className={clsx(classes.button, classes[`size${capitalize(size)}`])}
              disabled={disabled}
              onClick={handleButtonClick(1)}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            >
              <AddIcon color='action' fontSize='inherit' />
            </ButtonBase>
          </div>
          <div className={classes.rightAdornment}>{rightAdornment}</div>
        </div>
        {helperText && !statusText && <FormHelperText>{helperText}</FormHelperText>}
        {statusText && <FormHelperText>{statusText}</FormHelperText>}
      </FormControl>
    );
  },
);

QuantityField.displayName = 'QuantityField';

export default withThemeProps(themeSettings)(QuantityField);
