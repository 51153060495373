import React from 'react';
import { useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { apiClientState, authUserState } from '../appState';

export default function useCancelOrderForApprovalHandler(): [
  saveHandler: (otherOrderProps: OrderCancelOtherProps) => Promise<any>,
  savingInProgress: boolean,
] {
  const [CancelingInProgress, setCancelingInProgress] = React.useState<boolean>(false);
  const apiClient = useRecoilValue(apiClientState);
  const user = useRecoilValue(authUserState);

  const handler = React.useCallback(
    async (otherOrderProps: OrderCancelOtherProps): Promise<any> => {
      invariant(user, 'Unauthorized user can not save order!');

      setCancelingInProgress(true);
      await cancelOrderForApproval(apiClient, otherOrderProps);
      setCancelingInProgress(false);
    },
    [apiClient, user],
  );

  return [handler, CancelingInProgress];
}

const cancelOrderForApproval = (apiClient: ApiClient, params: OrderCancelOtherProps): Promise<any> => {
  return apiClient.post('/v1/shopping-cart/cancel-for-approval', params);
};

export interface OrderCancelOtherProps {
  cartId: string;
  purpose: string;
}
