import { selector } from 'recoil';
import { authState } from '../appState';

export const customerPriceGroupState = selector<string | undefined>({
  key: 'customerPriceGroup',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.priceGroup;
  },
});

export const customerContactGroupState = selector<string | undefined>({
  key: 'customerContactGroup',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.contactGroup;
  },
});

export const customerSupervisorState = selector<string | undefined>({
  key: 'customerSupervisor',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.supervisor || undefined;
  },
});

export const customerOrderTotalLimitState = selector<number | undefined>({
  key: 'customerOrderTotalLimit',
  get: ({ get }) => {
    const auth = get(authState);
    return typeof auth?.profile?.orderTotalLimit === 'number' ? auth?.profile?.orderTotalLimit : undefined;
  },
});
