import React from 'react';
import { useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import { ProductStockQuantities } from '../../@sprinx/knihovka-types';
import { apiClientState } from '../../api/appState';
import { shoppingCartState } from '../../api/shoppingCart';

const Context = React.createContext<ProductStockQuantities | null>(null);

export interface ProductStockProviderProps {
  children?: React.ReactNode;
}

/**
 * ProductStock component.
 *
 * @component
 */
const ProductStockProvider: React.FC<ProductStockProviderProps> = ({ children = null }) => {
  const apiClient = useRecoilValue(apiClientState);
  const cart = useRecoilValue(shoppingCartState);
  const [stockQuantites, setStockQuantities] = React.useState<ProductStockQuantities>({});
  const productIds = cart?.cartContent?.subjects.map((s) => {
    return s.product && typeof s.product != 'string' ? s.product.id : null;
  });
  const productKeys = productIds?.join(',');
  React.useEffect(() => {
    apiClient
      .post<ProductStockQuantities, {}>('/v1/product-stock-quantity', {
        products: productIds || [],
      })
      .then((res: ProductStockQuantities) => {
        setStockQuantities(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productKeys]);

  return <Context.Provider value={stockQuantites}>{children}</Context.Provider>;
};

ProductStockProvider.displayName = 'ProductStockProvider';

export default ProductStockProvider;

// ********* Hooks

/**
 * Return ProductStockProvider context.
 */
export const useProductStockQuantities = (): ProductStockQuantities => {
  const ctx = React.useContext(Context);

  invariant(ctx, "Dont call 'useProductStockQuantities' outside of <ProductStockProvider>");

  return ctx;
};
