import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export type ShoppingListsListSkuClassKey = 'root' | 'type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<ShoppingListsListSkuClassKey, {}>({
    root: {
      display: 'flex',
    },
    type: {
      paddingLeft: theme.spacing(1),
    },
  }),
);

const ShoppingListsListSku: React.FC<{
  sku: string;
}> = ({ sku }) => {
  const classes = useStyles();
  const t = useTranslateWithFallback();

  return (
    <div className={classes.root}>
      <span>
        {t('common/code')}: {sku}
      </span>
    </div>
  );
};

ShoppingListsListSku.displayName = 'ShoppingListsListSku';

export default ShoppingListsListSku;
