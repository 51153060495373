import { TranslationDictionary } from '@sprinx/react-globalize/types';
import kebabCase from 'lodash/kebabCase';
import usePreferredLocale from '@sprinx/react-globalize/usePreferredLocale';
import useRouteUrlFactory from './useRouteUrlFactory';

const useProductUrl = <T extends { extra?: Record<string, unknown>; id: string; name: TranslationDictionary }>(
  product: T,
): string => {
  const routeUrlFactory = useRouteUrlFactory();
  const locale = usePreferredLocale();

  const id = (product?.extra?.mainProductId as string) ?? product.id;
  const name = getProductName(product, locale);
  const sn = kebabCase(name) || id;
  const url = routeUrlFactory('product', { params: { id, sn } });

  return url;
};

function getProductName<T extends { extra?: Record<string, unknown>; id: string; name: TranslationDictionary }>(
  product: T,
  locale: string,
): string | undefined {
  if (typeof product.name === 'string') return product.name;

  const nameInLocale = product.name.find((i) => i.language === locale);
  if (nameInLocale) return nameInLocale.text;

  return product.name[0]?.text;
}

export default useProductUrl;
