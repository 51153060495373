import React from 'react';
import {
  AfterPageProps,
  createInitialPropsRedirectToLogin,
  makeAfterComponent,
  preventAuth,
  wrapLayout,
} from '../../@sprinx/react-after-razzle';
import ErrorInternal from '../../components/ErrorInternal';
import CustomerProfileShoppingListsPageLayout from './CustomerProfileShoppingListsPageLayout';

export type CustomerProfileShoppingListsPageProps = AfterPageProps;

const CustomerProfileShoppingListsPage: React.FC<CustomerProfileShoppingListsPageProps> = ({ error }) => {
  if (error) return <ErrorInternal error={error} />;

  return <CustomerProfileShoppingListsPageLayout />;
};

CustomerProfileShoppingListsPage.displayName = 'CustomerProfileShoppingListsPage';

export default makeAfterComponent(
  wrapLayout(CustomerProfileShoppingListsPage),
  preventAuth(async ({ match }) => {
    try {
      return {};
    } catch (error) {
      if (error.response?.status === 401) {
        return createInitialPropsRedirectToLogin({ match });
      }
      return { error };
    }
  }),
);
