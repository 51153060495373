import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ShoppingCartSubject, useAddToCart } from '../../../api/shoppingCart';
import QuantityField from '../../../components/QuantityField';

export interface CartSummarySubjectListItemAddToCartProps
  extends StandardDivProps<CartSummarySubjectListItemAddToCartClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemAddToCartClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectListItemAddToCart' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummarySubjectListItemAddToCartClassKey, {}>({
      root: {
        padding: theme.spacing(0, 2),
      },
    }),
  themeSettings,
);

const CartSummarySubjectListItemAddToCart = React.forwardRef<HTMLDivElement, CartSummarySubjectListItemAddToCartProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const { addToCart, ...addToCartProps } = useAddToCart({
      cartSubject: subject,
    });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <QuantityField onChange={addToCart} {...addToCartProps} fullWidth />
      </div>
    );
  },
);

CartSummarySubjectListItemAddToCart.displayName = 'CartSummarySubjectListItemAddToCart';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemAddToCart);
