import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import TextField from '@sprinx/react-mui-fields/TextField';
import clsx from 'clsx';
import React from 'react';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface CartExtraValues {
  noteForSeller: string;
  orderNumberExternal: string;
}

export interface CartSummaryExtraFieldsProps
  extends Omit<StandardDivProps<CartSummaryExtraFieldsClassKey>, 'onChange'> {
  onChange?: (nextValues: Partial<CartExtraValues>) => void;
  values: CartExtraValues;
}

export type CartSummaryExtraFieldsClassKey = 'root';

const themeSettings = { name: 'CartSummaryExtraFields' };
const useStyles = makeStyles(
  createStyles<CartSummaryExtraFieldsClassKey, {}>({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  themeSettings,
);

const CartSummaryExtraFields = React.forwardRef<HTMLDivElement, CartSummaryExtraFieldsProps>(
  ({ className, classes: pClasses, onChange, values }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslateWithFallback();

    const handleChange = (v: Partial<CartExtraValues>): void => {
      if (onChange) onChange(v);
    };

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <TextField
          label={t('common/externalOrderNumber')}
          value={values.orderNumberExternal}
          onChange={(v: string | undefined) => {
            const nv = v || '';
            if (nv.length <= 15) {
              handleChange({ orderNumberExternal: nv });
            }
          }}
        />

        <TextField
          label={t('common/supplierNote')}
          helperText={''}
          value={values.noteForSeller}
          onChange={(v: string | undefined) => {
            handleChange({ noteForSeller: v || '' });
          }}
          multiline
          rows={2}
        />
      </div>
    );
  },
);

CartSummaryExtraFields.displayName = 'CartSummaryExtraFields';

export default withThemeProps(themeSettings)(CartSummaryExtraFields);
