import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ShoppingCart } from '../../../api/shoppingCart';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CartSummarySubjectListItem from './CartSummarySubjectListItem';

export interface CartSummarySubjectsListProps extends StandardDivProps<CartSummarySubjectsListClassKey> {
  cart: ShoppingCart | undefined;
}

export type CartSummarySubjectsListClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectsList' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummarySubjectsListClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',

        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      },
    }),
  themeSettings,
);

const CartSummarySubjectsList = React.forwardRef<HTMLDivElement, CartSummarySubjectsListProps>(
  ({ cart, className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslateWithFallback();
    return !cart ? null : (
      <div ref={ref} className={clsx(classes.root, className)}>
        {cart && cart.cartContent.subjects && cart.cartContent.subjects.length > 0 ? (
          [...cart.cartContent.subjects]
            .reverse()
            .map((item) => <CartSummarySubjectListItem key={item.id} subject={item} />)
        ) : (
          <div>
            <Alert severity='error' variant='filled'>
              {t('shoppingCart/empty')}
            </Alert>
          </div>
        )}
      </div>
    );
  },
);

CartSummarySubjectsList.displayName = 'CartSummarySubjectsList';

export default withThemeProps(themeSettings)(CartSummarySubjectsList);
