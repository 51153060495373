import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import currencyFormatter from '@sprinx/formatting/currencyFormatter';

export interface ProductPriceElementProps extends StandardDivProps<ProductPriceElementClassKey> {
  currency: string;
  labelEnd?: React.ReactNode;
  labelStart?: React.ReactNode;
  labelUnit?: React.ReactNode;
  value: undefined | number;
}

export type ProductPriceElementClassKey = 'root' | 'value' | 'label' | 'unit';

const themeSettings = { name: 'ProductPriceElement' };
const useStyles = makeStyles(
  (/* theme */) => ({
    root: {},
    value: {
      whiteSpace: 'nowrap',
    },
    label: {},
    unit: {},

    // internal
    padLeft: {
      marginLeft: '1ch',
    },
    padRight: {
      marginRight: '1ch',
    },
  }),
  themeSettings,
);

const ProductPriceElement = React.forwardRef<HTMLDivElement, ProductPriceElementProps>(
  ({ className, classes: pClasses, currency, labelEnd, labelStart, labelUnit, value, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return value === undefined || value === null ? null : (
      <div ref={ref} className={clsx(classes.root, className)} {...rest}>
        {labelStart && <span className={clsx(classes.padRight, classes.label)}>{labelStart}</span>}
        <span className={classes.value}>{currencyFormatter(value, currency)}</span>
        {labelUnit && <span className={clsx(classes.padLeft, classes.unit)}>{labelUnit}</span>}
        {labelEnd && <span className={clsx(classes.padLeft, classes.label)}>{labelEnd}</span>}
      </div>
    );
  },
);

ProductPriceElement.displayName = 'ProductPriceElement';

export default withThemeProps(themeSettings)(ProductPriceElement);
