import { QueryOrderSettings, QueryPaginationSettings, TableFilterField } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import type { SupportedLocale } from '../../i18n/types';
import transformCart from './transformCart';

export type CartListItem = {
  createdAt: Date;
  extra: ExtraProps;
  href: string;
  id: string;
  informationId: string;
  owner: string;
  state: string;
};

export type ExtraProps = {
  orderProps: ExtraOrderProps;
};

export type ExtraOrderProps = {
  extra: ExtraOrderExtraProps;
  invoiceContact: InvoiceContactOrderProps;
};

export type ExtraOrderExtraProps = {
  abra_id: string;
};

export type InvoiceContactOrderProps = {
  id: string;
  name: string;
};

export const cartsQuery = selector<MoleculerListResult<CartListItem>>({
  key: 'carts',
  get: ({ get }) => {
    const initialState = get(cartsInitialState);
    if (initialState) {
      return initialState;
    }

    const apiClient = get(apiClientState);
    const params = get(cartsCallParamsState);
    const locale = get(localeState);
    get(cartsReloadTrigger);

    return getCarts(apiClient, params, locale);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(cartsReloadTrigger, (n) => n + 1);
    }
  },
});

export const cartsCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'cartsCallParams',
    default: createListInitialSettings({
      rowsPerPage: 10,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    }),
  }),
);

export const cartsInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<CartListItem> | undefined>({
    key: 'cartsInitialState',
    default: undefined,
  }),
);

export const cartsPaginationState = selector<QueryPaginationSettings & { total: number }>({
  key: 'cartsPagination',
  get: ({ get }): QueryPaginationSettings & { total: number } => {
    const params = get(cartsCallParamsState);
    const queryResult = get(cartsQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(cartsCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          // ...prevValue.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const cartsOrderByState = selector<QueryOrderSettings>({
  key: 'cartsOrderBy',
  get: ({ get }) => {
    const params = get(cartsCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(cartsCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const cartsFilterState = selector<TableFilterField<any>[]>({
  key: 'cartsFilter',
  get: ({ get }) => {
    const params = get(cartsCallParamsState);
    return (params.filter as TableFilterField<any>[]) || [];
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const nextFilter = newValue.filter(Boolean);

      set(
        cartsCallParamsState,
        (prev): ListCallParams => ({
          ...prev,
          filter: nextFilter,
          pagination: {
            ...prev.pagination,
            page: 1,
          },
        }),
      );
    }
  },
});

export function getCarts(
  apiClient: ApiClient,
  params: ListCallParams,
  locale: SupportedLocale,
): Promise<MoleculerListResult<any>> {
  return apiClient
    .get<MoleculerListResult<Omit<CartListItem, 'href'>>, Record<string, any>>(
      '/v1/shopping-cart/list',
      buildListQuery(params),
    )
    .then((r) => ({
      ...r,
      rows: transformCart(r.rows, locale),
    }));
}

export const cartsReloadTrigger = atom<number>({
  key: 'cartsReloadTrigger',
  default: 0,
});
