import React from 'react';
import { useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { apiClientState, authUserState } from '../appState';
import { shoppingSelectedDeliveryPlaceState, shoppingSelectedShippingState } from '../shopping';
import { shoppingCartState } from '../shoppingCart';
import { OrderSaveOtherProps } from './useOrderSaveHandler';

export default function useSaveForApprovalHandler(): [
  saveHandler: (otherOrderProps: OrderSaveOtherProps) => Promise<any>,
  savingInProgress: boolean,
] {
  const [savingInProgress, setSavingInProgress] = React.useState<boolean>(false);

  const apiClient = useRecoilValue(apiClientState);
  const cart = useRecoilValue(shoppingCartState);
  // const customer = useRecoilValue(shoppingCustomerSelector);
  const customer = useRecoilValue(shoppingSelectedDeliveryPlaceState);
  const shipping = useRecoilValue(shoppingSelectedShippingState);

  // const currency = useRecoilValue(currencyState);
  const user = useRecoilValue(authUserState);

  const handler = React.useCallback(
    async (otherOrderProps: OrderSaveOtherProps): Promise<any> => {
      invariant(cart, 'Cart not initialized!');
      invariant(customer, 'Customer not selected!');
      invariant(user, 'Unauthorized user can not save order!');
      invariant(shipping, 'Delivery type not selected!');

      const postAddress =
        (customer.postaddresses || [])?.length > 0 && customer.postaddresses?.find((i) => i.place)?.place?.full;

      const orderProps = {
        ...otherOrderProps,
        contact: customer.id,
        postAddress,
        invoiceContact: customer.type === 'office' ? customer.parentCompany : customer.id,
      };

      setSavingInProgress(true);
      const res = await saveForApproval(apiClient, { cartId: cart.id, orderProps });
      setSavingInProgress(false);
      return res;
    },
    [apiClient, cart, shipping, user, customer],
  );

  return [handler, savingInProgress];
}

const saveForApproval = (
  apiClient: ApiClient,
  params: {
    cartId: string;
    orderProps: OrderSaveOtherProps & {
      contact: string;
      invoiceContact: string;
    };
  },
): Promise<any> => {
  return apiClient.post('/v1/shopping-cart/save-for-approval', params);
};
