import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import clsx from 'clsx';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { localeState, paymentTypeLevelState } from '../../api/appState';
import { shoppingSelectedPaymentState, shoppingSelectedShippingState } from '../../api/shopping';
import { ShoppingCartPaymentProduct, ShoppingCartProduct, useShoppingCartSetPayment } from '../../api/shoppingCart';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import { filteringPaymentsByShipping } from '../../config';

export interface PickPaymentProps extends StandardDivProps<PickPaymentClassKey> {
  fullWidth?: boolean;
  payments: Array<ShoppingCartPaymentProduct> | [];
  required?: boolean;
}

export type PickPaymentClassKey =
  | 'root'
  | 'fullWidth'
  // | 'costs'
  | 'paper'
  | 'label'
  | 'helperText'
  | 'error'
  | 'warning'
  | 'selectField';

const themeSettings = { name: 'PickPayment' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PickPaymentClassKey, {}>({
      root: {
        paddingBottom: theme.spacing(2),
      },
      selectField: {
        [theme.breakpoints.down('sm')]: {
          '& div': {
            whiteSpace: 'initial',
          },
        },
      },
      fullWidth: {
        width: '100%',
      },
      // costs: {
      //   ...theme.typography.caption,
      //   paddingLeft: theme.spacing(2),
      // },
      paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
      },
      label: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
      },
      helperText: {
        ...theme.typography.body2,
        color: theme.palette.text.hint,
        textAlign: 'left',
      },
      error: {
        color: theme.palette.error.main,
      },
      warning: {
        color: theme.palette.warning.dark,
      },
    }),
  themeSettings,
);

const getPaymentName = (payment: ShoppingCartProduct, locale: string): string | undefined =>
  payment?.displayName && payment.displayName.find((n) => n.language === locale)?.text;

// const getPaymentPrice = (payment: ShoppingCartProduct, currency: string): number =>
//   (payment?.listPrice && payment.listPrice.find((n) => n.currency === currency)?.amount) || 0;

const PickPayment = React.forwardRef<HTMLDivElement, PickPaymentProps>(
  ({ className, classes: pClasses, fullWidth, required = false, payments = [], ...rest }, ref) => {
    const t = useTranslateWithFallback();
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const selectedShipping = useRecoilValue(shoppingSelectedShippingState);
    const [selectedPayment, updatePayment] = useRecoilState(shoppingSelectedPaymentState);
    const paymentTypeLevel = useRecoilValue(paymentTypeLevelState);

    const filteredPayments = React.useMemo(() => {
      return filteringPaymentsByShipping === true
        ? payments.filter(
            (p) =>
              (selectedShipping == null ||
                selectedShipping.allowedPaymentMethods == null ||
                selectedShipping.allowedPaymentMethods.length === 0 ||
                selectedShipping.allowedPaymentMethods.includes(p.sku)) &&
              (paymentTypeLevel == null ||
                (p.extra?.paymentTypeLevel != null && paymentTypeLevel >= p.extra.paymentTypeLevel)),
          )
        : payments;
    }, [paymentTypeLevel, payments, selectedShipping]);

    const addPaymentToCart = useShoppingCartSetPayment();

    const handleUpdatePaymentInCart = React.useCallback(
      (value: ShoppingCartProduct | undefined) => {
        updatePayment(value);
        return addPaymentToCart(value);
      },
      [addPaymentToCart, updatePayment],
    );

    return (
      <div ref={ref} className={clsx(classes.root, { [classes.fullWidth]: fullWidth }, className)} {...rest}>
        <div className={clsx(classes.label, { [classes.error]: required && !selectedPayment })}>
          {t('app/pickPayment/label')}
        </div>
        <div className={classes.paper}>
          <SelectField
            fullWidth
            onChange={handleUpdatePaymentInCart}
            value={selectedPayment}
            required={required}
            className={classes.selectField}
            disabled={filteredPayments.length === 0}
          >
            {filteredPayments.map((s: ShoppingCartProduct) => (
              <SelectFieldItem key={getPaymentName(s, locale)} value={s}>
                {s && getPaymentName(s, locale)}
                {/* {getPaymentPrice(s, currency) > 0 ? (
                  <span className={classes.costs}>{`${currencyFormatter(getPaymentPrice(s, currency), currency)} ${t(
                    'common/withoutTax',
                  )}`}</span>
                ) : (
                  <span className={classes.costs}>{t('common/free')}</span>
                )} */}
              </SelectFieldItem>
            ))}
          </SelectField>
        </div>
        {filteredPayments.length === 0 ? (
          <div className={clsx(classes.helperText, classes.warning)}>
            {t('app/pickPayment/shippingSelectionRequired')}
          </div>
        ) : (
          <div className={clsx(classes.helperText, { [classes.error]: required && !selectedPayment })}>
            {required && !selectedPayment ? t('app/pickPayment/requiredError') : t('app/pickPayment/helperText')}
          </div>
        )}
      </div>
    );
  },
);

PickPayment.displayName = 'PickPayment';

export default withThemeProps(themeSettings)(PickPayment);
