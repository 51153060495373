import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useRecoilValue } from 'recoil';
import currencyFormatter from '@sprinx/formatting/currencyFormatter';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { shoppingCartAmoutForFreeShippingSelector } from '../../api/shoppingCart';
import { currencyState } from '../../api/appState';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: theme.palette.error.main,
      fontWeight: 'bold',
    },
  }),
);
const CartBuyForFreeDelivery: FC<{ className?: string }> = ({ className }) => {
  const t = useTranslateWithFallback();
  const amountForFreeShipping = useRecoilValue(shoppingCartAmoutForFreeShippingSelector);
  const currency = useRecoilValue(currencyState);
  const classes = useStyles();

  if (amountForFreeShipping === null) {
    return null;
  }

  if (amountForFreeShipping === 0) {
    return <Typography className={clsx(classes.root, className)}>{t('common/youHaveFreeDelivery')}</Typography>;
  }

  return (
    <Typography className={clsx(classes.root, className)}>
      <span>
        {t('common/buyForFreeDeliveryAmount', {
          amount: currencyFormatter(amountForFreeShipping, currency ?? 'CZK'),
        })}
      </span>
      <span>{t('common/buyForFreeDeliveryRest')} </span>
    </Typography>
  );
};

CartBuyForFreeDelivery.displayName = 'CartBuyForFreeDelivery';

export default CartBuyForFreeDelivery;
