import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PricingConstants from '@sprinx/pricing-core/constants';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import clsx from 'clsx';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { localeState } from '../../api/appState';
import { shoppingSelectedPaymentState, shoppingSelectedShippingState } from '../../api/shopping';
import { ShoppingCartProduct, useUpdateCart } from '../../api/shoppingCart';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export interface PickShippingProps extends StandardDivProps<PickShippingClassKey> {
  fullWidth?: boolean;
  required?: boolean;
  shippings: Array<ShoppingCartProduct> | [];
}

export type PickShippingClassKey =
  | 'root'
  | 'fullWidth'
  // | 'costs'
  | 'paper'
  | 'label'
  | 'helperText'
  | 'error'
  | 'selectField';

const themeSettings = { name: 'PickShipping' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PickShippingClassKey, {}>({
      root: {
        paddingBottom: theme.spacing(2),
      },
      selectField: {
        [theme.breakpoints.down('sm')]: {
          '& div': {
            whiteSpace: 'initial',
          },
        },
      },
      fullWidth: {
        width: '100%',
      },
      // costs: {
      //   ...theme.typography.caption,
      //   paddingLeft: theme.spacing(2),
      // },
      paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
      },
      label: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
      },
      helperText: {
        ...theme.typography.body2,
        color: theme.palette.text.hint,
        textAlign: 'left',
      },
      error: {
        color: theme.palette.error.main,
      },
    }),
  themeSettings,
);

const getShippingName = (shipping: ShoppingCartProduct, locale: string): string | undefined =>
  shipping?.displayName && shipping.displayName.find((n) => n.language === locale)?.text;

// const getShippingPrice = (shipping: ShoppingCartProduct, currency: string): number =>
//   (shipping?.listPrice && shipping.listPrice.find((n) => n.currency === currency)?.amount) || 0;

const PickShipping = React.forwardRef<HTMLDivElement, PickShippingProps>(
  ({ className, classes: pClasses, fullWidth, required = false, shippings = [], ...rest }, ref) => {
    const t = useTranslateWithFallback();
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const [selectedShipping, updateShipping] = useRecoilState(shoppingSelectedShippingState);
    const updatePayment = useSetRecoilState(shoppingSelectedPaymentState);

    const updateCart = useUpdateCart();

    const handleUpdateShippingInCart = React.useCallback(
      (value: ShoppingCartProduct | undefined) => {
        updatePayment(undefined);
        updateShipping(value);
        return updateCart([
          { product: value, quantity: 1, subjectId: PricingConstants.SHIPPING_ID },
          { product: PricingConstants.PAYMENT_DEFAULT_PRODUCT, quantity: 0, subjectId: PricingConstants.PAYMENT_ID },
        ]);
      },
      [updateCart, updatePayment, updateShipping],
    );

    return (
      <div ref={ref} className={clsx(classes.root, { [classes.fullWidth]: fullWidth }, className)} {...rest}>
        <div className={clsx(classes.label, { [classes.error]: required && !selectedShipping })}>
          {t('app/pickShipping/label')}
        </div>
        <div className={classes.paper}>
          <SelectField
            fullWidth
            onChange={handleUpdateShippingInCart}
            value={selectedShipping}
            required={required}
            className={classes.selectField}
          >
            {shippings.map((s: ShoppingCartProduct) => (
              <SelectFieldItem key={getShippingName(s, locale)} value={s}>
                {s && getShippingName(s, locale)}
                {/* {getShippingPrice(s, currency) > 0 ? (
                  <span className={classes.costs}>{`${currencyFormatter(getShippingPrice(s, currency), currency)} ${t(
                    'common/withoutTax',
                  )}`}</span>
                ) : (
                  <span className={classes.costs}>{t('common/free')}</span>
                )} */}
              </SelectFieldItem>
            ))}
          </SelectField>
        </div>
        <div className={clsx(classes.helperText, { [classes.error]: required && !selectedShipping })}>
          {required && !selectedShipping ? t('app/pickShipping/requiredError') : t('app/pickShipping/helperText')}
        </div>
      </div>
    );
  },
);

PickShipping.displayName = 'PickShipping';

export default withThemeProps(themeSettings)(PickShipping);
