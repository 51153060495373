import React from 'react';
import ShoppingListsDialogCreate from '../ShoppingListsDialogCreate';
import ShoppingListsDialogSelect from '../ShoppingListsDialogSelect';

const ShoppingListsActionsHolder: React.FC = () => (
  <>
    <ShoppingListsDialogCreate />
    <ShoppingListsDialogSelect />
  </>
);

ShoppingListsActionsHolder.displayName = 'ShoppingListsActionsHolder';

export default ShoppingListsActionsHolder;
