import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import invariant from 'tiny-invariant';
import { EntityId } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { AppUser } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, authUserState, currencyState } from '../appState';
import {
  ShoppingCustomerContact,
  shoppingSelectedDeliveryPlaceState,
  shoppingSelectedShippingState,
} from '../shopping';
import { shoppingCartState } from '../shoppingCart';
import { ordersQuery } from './orders';

export default function useOrderSaveHandler(): [
  saveHandler: (otherOrderProps: OrderSaveOtherProps) => Promise<any>,
  savingInProgress: boolean,
] {
  const [savingInProgress, setSavingInProgress] = React.useState<boolean>(false);

  const apiClient = useRecoilValue(apiClientState);
  const cart = useRecoilValue(shoppingCartState);
  // const customer = useRecoilValue(shoppingCustomerSelector);
  const customer = useRecoilValue(shoppingSelectedDeliveryPlaceState);
  const shipping = useRecoilValue(shoppingSelectedShippingState);
  const currency = useRecoilValue(currencyState);
  const user = useRecoilValue(authUserState);
  const ordersReset = useResetRecoilState(ordersQuery);

  // console.log('cart', cart, 'customer', customer, 'currency', currency, 'user', user);

  const handler = React.useCallback(
    async (otherOrderProps: OrderSaveOtherProps): Promise<any> => {
      invariant(cart, 'Cart not initialized!');
      invariant(customer, 'Customer not selected!');
      invariant(user, 'Unauthorized user can not save order!');
      invariant(shipping, 'Delivery type is not selected!');

      setSavingInProgress(true);
      const savedOrder = await orderCreate(
        apiClient,
        convertCartToOrder(customer, user, currency, cart.id, otherOrderProps),
      );
      setSavingInProgress(false);
      ordersReset();
      return savedOrder;
    },
    [apiClient, cart, currency, customer, shipping, ordersReset, user],
  );

  return [handler, savingInProgress];
}

export function useOrderSaveFromCartHandler(): [
  saveHandler: (otherOrderProps: OrderSaveOtherProps) => Promise<any>,
  savingInProgress: boolean,
] {
  const [savingInProgress, setSavingInProgress] = React.useState<boolean>(false);

  const apiClient = useRecoilValue(apiClientState);
  // const cart = useRecoilValue(shoppingCartState);
  // const customer = useRecoilValue(shoppingCustomerSelector);
  // const customer = useRecoilValue(shoppingSelectedDeliveryPlaceState);
  const currency = useRecoilValue(currencyState);
  const user = useRecoilValue(authUserState);
  const ordersReset = useResetRecoilState(ordersQuery);

  // console.log('cart', cart, 'customer', customer, 'currency', currency, 'user', user);

  const handler = React.useCallback(
    async (otherOrderProps: OrderSaveOtherProps): Promise<any> => {
      invariant(otherOrderProps.cartId, 'Cart not initialized!');
      // invariant(customer, 'Customer not selected!');
      invariant(user, 'Unauthorized user can not save order!');

      setSavingInProgress(true);
      const savedOrder = await orderFromCartCreate(apiClient, getParams(user, currency, otherOrderProps));
      setSavingInProgress(false);
      ordersReset();
      return savedOrder;
    },
    [apiClient, currency, ordersReset, user],
  );

  return [handler, savingInProgress];
}

const orderCreate = (apiClient: ApiClient, params: OrderRecordCreate): Promise<any> => {
  return apiClient.post('/v1/orders', params);
};

const orderFromCartCreate = (apiClient: ApiClient, params: any): Promise<any> => {
  return apiClient.post('/v1/orders-from-cart', params);
};

const getParams = (user: AppUser, currency: string, otherOrderProps: OrderSaveOtherProps): any => {
  return {
    ...otherOrderProps,
    // cartId,
    currency,
    dateOfOrder: new Date(),
    approver: user,
    source: 'virtual-rep',
  };
};

const convertCartToOrder = (
  customer: ShoppingCustomerContact,
  user: AppUser,
  currency: string,
  // cartContent: ShoppingCartContent,
  cartId: EntityId,
  otherOrderProps: OrderSaveOtherProps,
): OrderRecordCreate => {
  return {
    ...otherOrderProps,
    cartId,
    currency,
    contact: customer,
    invoiceContact: customer.type === 'office' ? customer.parentCompany : customer,

    dateOfOrder: new Date(),

    // header: {
    //   // TODO: add more fields
    //   createdByShopUser: user,
    // },
    // contactPerson: undefined,
    // deliveryPostalAddress: undefined,

    // opportunity: undefined,
    // offer: undefined,

    // subjects: convertSubjects(cartContent),
    // total: cartContent.total,
    // totalWithTax: cartContent.totalWithTax,

    // taxes: undefined,
    // extra: undefined,
    // externals: undefined,

    owner: user,

    source: 'virtual-rep',
  };
};

// const convertSubjects = (cartContent: ShoppingCartContent): ShoppingCartSubject[] => {
//   const cs = (ss: any): ShoppingCartSubject[] =>
//     Array.isArray(ss) ? ss.reduce((r, { discounts, indirectSubjects: is, ...s }) => [...r, s, ...cs(is)], []) : [];

//   return cs([...cartContent.subjects, /* cart.payment, cart.shipping, */ ...(cartContent.indirectSubjects || [])]);
// };

export interface OrderRecordCreate {
  cartId: EntityId;
  contact: {
    id: string;
  };
  currency: string;
  dateOfOrder: Date;
  expectedDeliveryDate: Date;
  extra: {
    externalOrderNumber?: string;
    locationDelivery?: string;
    salesman?: string;
    subjectId?: string;
  };

  invoiceContact:
    | {
        id: string;
      }
    | string;

  noteForSeller?: string;
  owner: { id: string };

  source: string;

  // subjects: {
  //   id: string;
  //   price: number;
  //   priceWithTax: number | undefined;
  //   product: string | { id: string; sku: string };
  //   quantity: number;
  // }[];
  // total: number;
  // totalWithTax: number;
}

export interface OrderSaveOtherProps {
  cartId?: EntityId;
  expectedDeliveryDate: Date;
  extra: {
    externalOrderNumber?: string;
    locationDelivery?: string;
    requestedDeliveryDate?: Date | null;
    salesman?: string;
    subjectId?: string;
  };
  noteForSeller?: string;
}
