import FormikExtended from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface ShoppingListsDialogSaveProps<T extends { purpose: string }> {
  initialValues: T;
  onClose?: () => void;
  onSave?: (purpose: string) => void;
  open?: boolean;
}

const ShoppingListsDialogSavePurpose = <T extends { purpose: string }>({
  initialValues,
  open,
  onClose,
  onSave,
}: ShoppingListsDialogSaveProps<T>): JSX.Element | null => {
  const t = useTranslateWithFallback();

  const handleClose = React.useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleSave = React.useCallback(
    (data): Promise<any> => {
      if (onSave) onSave(data);
      return Promise.resolve();
    },
    [onSave],
  );

  return open && initialValues ? (
    <FormikExtended<T, { purpose: string }, { purpose: boolean }>
      onSave={handleSave}
      onFormReleased={async (r) => {
        handleClose();
        return r;
      }}
      initialValues={initialValues}
      validationFastest
      validationSchema={{
        purpose: { type: 'string', empty: false },
      }}
      render={({ dirty, onSubmit, errors, onChange: handleChange, values }) => (
        <WrapDialogForm
          onSubmit={onSubmit}
          onClose={handleClose}
          dirty={dirty}
          dialogTitle={t('orderDisapprovalPage/dialogTitle')}
          open
          dialogProps={{
            fullWidth: true,
            maxWidth: 'sm',
          }}
          render={() => (
            <TextField
              label={t('orderDisapprovalPage/reason/label')}
              helperText={t('orderDisapprovalPage/reason/helperText')}
              value={values.purpose}
              error={errors.purpose}
              onChange={handleChange('purpose')}
              fullWidth
            />
          )}
        />
      )}
    />
  ) : null;
};

ShoppingListsDialogSavePurpose.displayName = 'ShoppingListsDialogSavePurpose';

export default ShoppingListsDialogSavePurpose;
