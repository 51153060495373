import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButtonDelete from '@sprinx/react-mui-components/IconButtonDelete';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ShoppingCartSubject, useRemoveFromCart } from '../../../api/shoppingCart';

export interface CartSummarySubjectListItemActionsProps
  extends StandardDivProps<CartSummarySubjectListItemActionsClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemActionsClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectListItemActions' };
const useStyles = makeStyles(
  createStyles<CartSummarySubjectListItemActionsClassKey, {}>({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  themeSettings,
);

const CartSummarySubjectListItemActions = React.forwardRef<HTMLDivElement, CartSummarySubjectListItemActionsProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const removeFromCart = useRemoveFromCart({
      cartSubject: subject,
    });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <IconButtonDelete onClick={() => removeFromCart()} />
      </div>
    );
  },
);

CartSummarySubjectListItemActions.displayName = 'CartSummarySubjectListItemActions';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemActions);
