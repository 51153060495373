import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteTwoTone from '@material-ui/icons/DeleteTwoTone';
import DeleteForeverTwoTone from '@material-ui/icons/DeleteForeverTwoTone';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { useCartReset } from '../../api/shoppingCart';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

type CartResetButtonClassKey = 'root';
const themeSettings = { name: 'CartResetButton' };
const useStyles = makeStyles(
  () =>
    createStyles<CartResetButtonClassKey, {}>({
      root: {},
    }),
  themeSettings,
);

const CartResetButton = React.forwardRef<HTMLButtonElement, ButtonProps & { iconOnly?: boolean }>(
  ({ classes: pClasses, className, variant = 'outlined', size = 'small', iconOnly = false, ...rest }, ref) => {
    const t = useTranslateWithFallback();
    const classes = useStyles({ classes: pClasses, className });
    const cartReset = useCartReset();
    const [clicked, setClicked] = React.useState<NodeJS.Timeout | null>(null);

    const handleConfirmed = React.useCallback(() => {
      if (clicked) {
        clearTimeout(clicked);
        cartReset();
        setClicked(null);
      } else {
        setClicked(null);
      }
    }, [cartReset, clicked]);

    const handleButtonClick = React.useCallback(() => {
      if (!clicked) {
        setClicked(
          setTimeout(() => {
            setClicked(null);
          }, 4000),
        );
        return;
      }

      handleConfirmed();
    }, [clicked, handleConfirmed]);

    const handleClickOutside = React.useCallback(
      (/* event */) => {
        if (clicked) {
          setClicked(null);
        }
      },
      [clicked],
    );

    return (
      <div className={clsx(classes.root, className)}>
        <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={() => handleClickOutside()}>
          {iconOnly ? (
            <IconButton color={clicked ? 'secondary' : 'inherit'} onClick={handleButtonClick} ref={ref}>
              {clicked ? <DeleteForeverTwoTone /> : <DeleteTwoTone />}
            </IconButton>
          ) : (
            <Button
              ref={ref}
              variant={variant}
              size={size}
              color={clicked ? 'secondary' : 'default'}
              onClick={handleButtonClick}
              {...rest}
            >
              {clicked ? t('common/confirmReset') : t('common/resetCart')}
            </Button>
          )}
        </ClickAwayListener>
      </div>
    );
  },
);

CartResetButton.displayName = 'CartResetButton';

export default CartResetButton;
