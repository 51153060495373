import FormikExtended from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export interface ShoppingListsDialogSaveProps<T extends { name: string }> {
  initialValues: T;
  onClose?: () => void;
  onSave?: (data: T) => void;
  open?: boolean;
}

const ShoppingListsDialogSave = <T extends { name: string }>({
  initialValues,
  open,
  onClose,
  onSave,
}: ShoppingListsDialogSaveProps<T>): JSX.Element | null => {
  const t = useTranslateWithFallback();
  const handleClose = React.useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleSave = React.useCallback(
    (data: T): Promise<any> => {
      if (onSave) onSave(data);
      return Promise.resolve();
    },
    [onSave],
  );

  return open ? (
    <FormikExtended<T, { name: string }, { name: boolean }>
      onSave={handleSave}
      onFormReleased={async (r) => {
        handleClose();
        return r;
      }}
      initialValues={initialValues}
      validationFastest
      validationSchema={{
        name: { type: 'string', empty: false },
      }}
      render={({ dirty, onSubmit, errors, onChange: handleChange, values }) => (
        <WrapDialogForm
          onSubmit={onSubmit}
          onClose={handleClose}
          dirty={dirty}
          dialogTitle={t('customerProfileShoppingListsPage/createShoppingList')}
          open
          dialogProps={{
            fullWidth: true,
            maxWidth: 'sm',
          }}
          render={() => (
            <TextField
              label={t('customerProfileShoppingListsPage/shoppingListName')}
              helperText={t('customerProfileShoppingListsPage/shoppingListHelperText')}
              value={values.name}
              error={errors.name}
              onChange={handleChange('name')}
              fullWidth
            />
          )}
        />
      )}
    />
  ) : null;
};

ShoppingListsDialogSave.displayName = 'ShoppingListsDialogSave';

export default ShoppingListsDialogSave;
