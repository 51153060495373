import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import { ShoppingCartSubject } from '../../../api/shoppingCart';
import ProductPriceElement from '../../../components/ProductPriceElement';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface CartSummarySubjectListItemPriceProps
  extends StandardDivProps<CartSummarySubjectListItemPriceClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemPriceClassKey = 'root';

const themeSettings = { name: 'CartSummarySubjectListItemPrice' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummarySubjectListItemPriceClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
        },
      },
    }),
  themeSettings,
);

const CartSummarySubjectListItemPrice = React.forwardRef<HTMLDivElement, CartSummarySubjectListItemPriceProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const currency = useRecoilValue(currencyState);
    const t = useTranslateWithFallback();

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <PrimaryPrice value={subject.price} currency={currency} labelStart={t('common/withoutTax')} />
        {(subject?.taxInfo?.value ?? 0) > 0 && (
          <SecondaryPrice value={subject.priceWithTax} currency={currency} labelStart={t('common/withTax')} />
        )}
      </div>
    );
  },
);

CartSummarySubjectListItemPrice.displayName = 'CartSummarySubjectListItemPrice';

export default withThemeProps(themeSettings)(CartSummarySubjectListItemPrice);

const PrimaryPrice = withStyles((theme) => ({
  root: {
    ...theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
}))(ProductPriceElement) as typeof ProductPriceElement;

const SecondaryPrice = withStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.subtitle1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
  },
}))(ProductPriceElement) as typeof ProductPriceElement;
