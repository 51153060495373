import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ListPretty from '@sprinx/react-mui-components/ListPretty';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { shoppingListsListQuery } from '../../api/shoppingLists';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ShoppingListItem from './components/ShoppingListItem';

export interface ShoppingListsListProps extends StandardDivProps<ShoppingListsListClassKey> {
  /**
   * If `true` list will be edit mode. This is used in administration of shopping lists.
   */
  enabeleEditation?: boolean;
}

export type ShoppingListsListClassKey = 'root';

const themeSettings = { name: 'ShoppingListsList' };
const useStyles = makeStyles(
  createStyles<ShoppingListsListClassKey, {}>({
    root: {},
  }),
  themeSettings,
);

const ShoppingListsList = React.forwardRef<HTMLDivElement, ShoppingListsListProps>(
  ({ className, classes: pClasses, enabeleEditation = false }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <ErrorBoundary fallback={<div>Chyba zobrazeni seznamu...</div>}>
          <React.Suspense fallback={<ShoppingListsListItemSkeleton />}>
            <ShoppingListsListInner enabeleEditation={enabeleEditation} />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    );
  },
);

ShoppingListsList.displayName = 'ShoppingListsList';

export default withThemeProps(themeSettings)(ShoppingListsList);

const ShoppingListsListItemSkeleton = (): JSX.Element => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <ShoppingListsListInnerItemSkeleton />
    <ShoppingListsListInnerItemSkeleton />
  </div>
);
const ShoppingListsListInnerItemSkeleton = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  primary: {
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  secondary: {
    height: theme.spacing(8),
    display: 'flex',
  },
  secondaryActions: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))(({ classes }: { classes: Record<string, string> }): JSX.Element => {
  return (
    <div className={classes.root}>
      <div className={classes.primary}>
        <Skeleton variant='circle' width={40} height={40} />
        <div className={classes.text}>
          <Skeleton variant='text' width={'40%'} height={'2rem'} />
          <Skeleton variant='text' width={'25%'} />
        </div>
      </div>
      <div className={classes.secondary}>
        <Skeleton variant='rect' width={32} height={32} style={{ marginRight: 16 }} />
        <Skeleton variant='rect' width={32} height={32} style={{ marginRight: 16 }} />

        <div className={classes.secondaryActions}>
          <Skeleton variant='rect' width={240} height={40} />
        </div>
      </div>
    </div>
  );
});

const ShoppingListsListInner: React.FC<{
  enabeleEditation: boolean;
}> = ({ enabeleEditation }) => {
  const shoppingLists = useRecoilValue(shoppingListsListQuery);

  return (
    <ListPretty>
      {shoppingLists.map((shoppingList, index) => (
        <ShoppingListItem key={index.toString()} shoppingList={shoppingList} enabeleEditation={enabeleEditation} />
      ))}
    </ListPretty>
  );
};
