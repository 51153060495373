import Link, { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ProductDetailLinkProps extends Omit<LinkProps<RouterLink>, 'classes'> {
  children: NonNullable<React.ReactNode>;
  classes?: Partial<Record<ProductDetailLinkClassKey, string>>;
}

export type ProductDetailLinkClassKey = 'root';

const themeSettings = { name: 'ProductDetailLink' };
const useStyles = makeStyles(
  (/* theme */) => ({
    root: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
  themeSettings,
);

const ProductDetailLink = React.forwardRef<HTMLAnchorElement, ProductDetailLinkProps>(
  ({ children, className, classes: pClasses, to, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <Link ref={ref} className={clsx(classes.root, className)} component={RouterLink} to={to} {...rest}>
        {children}
      </Link>
    );
  },
);

ProductDetailLink.displayName = 'ProductDetailLink';

export default withThemeProps(themeSettings)(ProductDetailLink);
