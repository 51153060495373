import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogButtonCancel from '@sprinx/react-mui-components/DialogButtonCancel';
import React from 'react';
import { useRecoilState } from 'recoil';
import { shoppingListsSelectRequestState } from '../../api/shoppingLists';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import ShoppingListsList from '../ShoppingListsList';

const ShoppingListsDialogSelect: React.FC = () => {
  const t = useTranslateWithFallback();
  const [open, setOpen] = useRecoilState(shoppingListsSelectRequestState);

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle>{t('customerProfileOrderDetailPage/dialogTitle')}</DialogTitle>
      <DialogContent>
        <ShoppingListsList />
      </DialogContent>
      <DialogActions>
        <DialogButtonCancel
          onClick={() => {
            setOpen(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

ShoppingListsDialogSelect.displayName = 'ShoppingListsDialogSelect';

export default ShoppingListsDialogSelect;
