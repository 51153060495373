import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import Page from '../../@sprinx/react-web/Page';
import PageHeadline from '../../@sprinx/react-web/PageHeadline';
import CustomerProfileNavigation from '../../components/CustomerProfileNavigation';
import ShoppingListsDialogRename from '../../components/ShoppingListsDialogRename';
import ShoppingListsList from '../../components/ShoppingListsList';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export type CustomerProfileShoppingListsPageLayoutProps =
  StandardDivProps<CustomerProfileShoppingListsPageLayoutClassKey>;

export type CustomerProfileShoppingListsPageLayoutClassKey = 'root' | 'list';

const themeSettings = { name: 'CustomerProfileShoppingListsPageLayout' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CustomerProfileShoppingListsPageLayoutClassKey, {}>({
      root: {},
      list: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('xs')]: {
          padding: 0,
        },
      },
    }),
  themeSettings,
);

const CustomerProfileShoppingListsPageLayout = React.forwardRef<
  HTMLDivElement,
  CustomerProfileShoppingListsPageLayoutProps
>(({ className, classes: pClasses }, ref) => {
  const classes = useStyles({ classes: pClasses });
  const t = useTranslateWithFallback();

  return (
    <Page
      ref={ref}
      className={clsx(classes.root, className)}
      pageTitle={t('customerProfileShoppingListsPage/title')}
      breadcrumbs={<CustomerProfileNavigation />}
    >
      <PageHeadline>{t('customerProfileShoppingListsPage/title')}</PageHeadline>
      <ShoppingListsList className={classes.list} enabeleEditation />
      <ShoppingListsDialogRename />
    </Page>
  );
});

CustomerProfileShoppingListsPageLayout.displayName = 'CustomerProfileShoppingListsPageLayout';

export default withThemeProps(themeSettings)(CustomerProfileShoppingListsPageLayout);
