import { useCallback, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { loaderOpenState } from '../state/atoms';

const useCallbackWithLoader = <T extends (...args: any[]) => any>(callback: T): T => {
  const setLoading = useSetRecoilState(loaderOpenState);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const wrappedCallback = useCallback(
    async (...args) => {
      setLoading(true);
      await callbackRef.current?.(...args);
      setLoading(false);
    },
    [setLoading],
  );

  return wrappedCallback as T;
};

export default useCallbackWithLoader;
