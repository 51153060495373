import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import currencyFormatter from '@sprinx/formatting/currencyFormatter';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import DialogConfirm from '../../../@sprinx/react-mui-components/DialogConfirm';
import { currencyState } from '../../../api/appState';
import { customerPriceGroupState } from '../../../api/customer/customer';
import { CatalogueProduct, productVariantsQuery } from '../../../api/products/catalogue';
import { ShoppingCartProduct, ShoppingCartSubject, useAddToCart, useRemoveFromCart } from '../../../api/shoppingCart';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
export interface CartSummarySubjectListItemWarningProps
  extends StandardDivProps<CartSummarySubjectListItemWarningClassKey> {
  subject: ShoppingCartSubject;
}

export type CartSummarySubjectListItemWarningClassKey = 'root';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    color: theme.palette.warning.main,
    marginBottom: theme.spacing(1),
    alignItems: 'flex-end',
  },
  button: {
    ...theme.typography.caption,
    color: theme.palette.warning.main,
    cursor: 'pointer',
    outline: 'inherit',
    background: 'none',
    border: 'none',
    paddingLeft: 0,
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
    fontSize: '0.9rem',
  },
  variants: {
    border: '1px solid #dcdcdcd6',
    padding: '0.75rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  pricing: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '0.875rem',
    color: 'black',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.9rem',
      alignItems: 'center',
    },
    '& > div > span:first-child': {
      fontSize: '14px',
    },
    '& > div > span:last-child': {
      fontSize: '12px',
    },
  },
  productName: {
    color: 'black',
    '& > p': {
      fontSize: '0.875rem',
    },
  },
  inStock: {
    color: '#4caf50',
  },
  notInStock: {
    color: '#f44336',
  },
  product: {
    '&:not(:last-child)': {
      paddingBottom: '1rem',
      borderBottom: '1px solid #0000001f',
    },
    '&:only-child': {
      paddingBottom: '0rem',
      borderBottom: 'none',
    },
  },
  wrapper: {
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div > div > span:first-child': {
      paddingRight: '0.5rem',
      color: '#0000008a',
      fontSize: '0.875rem',
    },
    '& > div > div:nth-child(3) > span:first-child': {
      paddingRight: '0.2rem',
      fontSize: '12px',
    },
    '& > div > div > span:nth-child(2)': {
      color: 'black',
    },
  },
  addToCart: {
    marginLeft: 'auto',
    gridArea: 'addToCart',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      '-ms-grid-row': 1,
      '-ms-grid-column': 5,
      '-ms-grid-row-span': 2,
    },
  },
  sku: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CartSummarySubjectListItemWarning: React.FC<CartSummarySubjectListItemWarningProps> = ({
  subject,
  className,
}) => {
  const priceGroup = useRecoilValue(customerPriceGroupState);
  const warningText = (subject.product as ShoppingCartProduct).taxonomies?.includes(`/variants/${priceGroup}`) || null;
  const classes = useStyles();

  const [showVariants, setShowVariants] = useState(false);
  const handleShowVariants = React.useCallback(() => {
    setShowVariants((prev) => !prev);
  }, []);

  return warningText ? (
    <React.Suspense fallback={<></>}>
      <div className={clsx(classes.root, className)}>
        <CartSummarySubjectListItemWarningButton handleShowVariants={handleShowVariants} showVariants={showVariants} />
        {showVariants ? <CartSummarySubjectListItemWarningVariants subject={subject} /> : null}
      </div>
    </React.Suspense>
  ) : null;
};

const CartSummarySubjectListItemWarningButton: React.FC<{
  handleShowVariants: () => void;
  showVariants: boolean;
}> = ({ handleShowVariants, showVariants }) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={handleShowVariants}>
      <InfoIcon fontSize='small' />
      {showVariants ? 'Skrýt' : 'Zobrazit'} levnější varianty produktu
    </button>
  );
};

const CartSummarySubjectListItemWarningVariants: React.FC<{ subject: ShoppingCartSubject }> = ({ subject }) => {
  const classes = useStyles();
  const { pn } = subject.product as ShoppingCartProduct;
  const variants = useRecoilValue(productVariantsQuery(pn as string));
  const currency = useRecoilValue(currencyState);
  const t = useTranslateWithFallback();

  return (
    <div className={classes.variants}>
      {variants.rows.slice(1).map((variant, index) => (
        <div key={index} className={classes.product}>
          <div className={classes.wrapper}>
            <div className={classes.productName}>
              <p>
                <strong>{variant.displayName && variant.displayName[0].text}</strong>
              </p>
              <span className={classes.sku}>
                <span>
                  {t('common/code')}: {variant.sku || ''}
                </span>
              </span>
            </div>
            <div style={{ paddingTop: '0.875rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {variant.stockQuantity && variant.stockQuantity.available ? (
                <span className={classes.inStock}>
                  {variant.stockQuantity.detail && variant.stockQuantity.detail[0].quantity}{' '}
                  {t('productPage/pcsInStock')}
                </span>
              ) : (
                <span className={classes.notInStock}>{t('common/notInStock')}</span>
              )}

              <div>
                <span>s DPH</span>
                <span style={{ fontSize: '18.2864px', fontWeight: 500 }}>
                  {currencyFormatter(variant.priceWithTax, currency)}
                </span>
              </div>

              <div>
                <span>{t('common/withoutTax')} </span>
                <span style={{ fontSize: '16px' }}>{currencyFormatter(variant.price, currency)}</span>
              </div>
            </div>
          </div>

          <div className={classes.pricing}>
            <div>
              {variant && variant.extra && variant.extra.packSize && variant.extra.type === 'pack' ? (
                <>
                  <span>
                    {t('common/withTaxPerPc')}{' '}
                    <span style={{ fontSize: '16px' }}>
                      {currencyFormatter(
                        variant.priceWithTax ? variant.priceWithTax / variant.extra.packSize : variant.priceWithTax,
                        currency,
                      )}
                    </span>
                  </span>
                  <span>
                    {t('common/withTaxPerPc')}{' '}
                    <span style={{ fontSize: '16px' }}>
                      {currencyFormatter(
                        variant.price ? variant.price / variant.extra.packSize : variant.price,
                        currency,
                      )}
                    </span>
                  </span>
                </>
              ) : null}
            </div>
            <CartSummarySubjectListItemWarningReplace
              className={classes.addToCart}
              subject={subject}
              variant={variant}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const CartSummarySubjectListItemWarningReplace: React.FC<{
  className: Object;
  subject: ShoppingCartSubject;
  variant: CatalogueProduct;
}> = ({ subject, variant }) => {
  const [showConfirmSave, setShowConfirmSave] = React.useState<boolean>(false);
  const t = useTranslateWithFallback();

  const { addToCart, quantity } = useAddToCart({ product: variant });

  const handleDialog = React.useCallback(() => {
    setShowConfirmSave(true);
  }, []);

  const removeFromCart = useRemoveFromCart({
    cartSubject: subject,
  });

  const onConfirm = React.useCallback(async () => {
    setShowConfirmSave(false);

    const toBeAdded =
      variant && variant.extra && variant.extra.type === 'pack' && variant.extra.packSize
        ? Math.ceil(subject.quantity / variant.extra.packSize)
        : subject.quantity;

    await addToCart((quantity || 0) + toBeAdded);

    await removeFromCart();
  }, [addToCart, quantity, removeFromCart, subject.quantity, variant]);

  return (
    <>
      <Button color='primary' variant='contained' onClick={() => handleDialog()}>
        {t('app/replace')}
      </Button>

      <DialogConfirm
        open={showConfirmSave}
        onClose={() => {
          setShowConfirmSave(false);
        }}
        onConfirm={() => {
          onConfirm();
        }}
        title={t('shoppingCart/replacingProduct')}
      >
        <div>
          {variant && variant.extra && variant.extra.packSize && variant.extra.type === 'pack'
            ? `${subject.quantity} kusů původního zboží bude nahrazeno ${Math.ceil(
                subject.quantity / variant.extra.packSize,
              )}

              ${Math.ceil(subject.quantity / variant.extra.packSize) === 1 ? 'balením' : 'baleními'}


               (${variant.extra.packSize} kusů v jednom balení)`
            : `Přejete si nahradit původní produkt nově vybraným produktem?`}
        </div>
      </DialogConfirm>
    </>
  );
};

export default CartSummarySubjectListItemWarning;
