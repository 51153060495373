import React from 'react';
import {
  AfterPageProps,
  createApiClient,
  createInitialPropsRedirectToLogin,
  makeAfterComponent,
  preventAuth,
  wrapLayout,
} from '../../@sprinx/react-after-razzle';
import commonThingsToLoad from '../../api/commonThingsToLoad';
import ErrorInternal from '../../components/ErrorInternal';
import CartSummaryPageLayout from './CartSummaryPageLayout';

export type CartSummaryPageProps = AfterPageProps;

const CartSummaryPage: React.FC<CartSummaryPageProps> = ({ error }) => {
  if (error) return <ErrorInternal error={error} />;

  return <CartSummaryPageLayout />;
};

CartSummaryPage.displayName = 'CartSummaryPage';

export default makeAfterComponent(
  wrapLayout(CartSummaryPage),
  preventAuth(async ({ match, initialAppProps }) => {
    try {
      if (initialAppProps) {
        const appProps = initialAppProps;
        const apiClient = createApiClient({ accessToken: appProps.auth?.accessToken });
        const commonThings = await Promise.all(commonThingsToLoad.promises({ apiClient }));

        appProps.propagateToState({
          ...commonThingsToLoad.toRegister(commonThings),
        });
      }
      return {};
    } catch (error) {
      if (error.response?.status === 401) {
        return createInitialPropsRedirectToLogin({ match });
      }
      return { error };
    }
  }),
);
