import { darken, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ShoppingCartSubject } from '../../../api/shoppingCart';
import CartSummarySubjectListItemImage from '../../CartSummaryPage/components/CartSummarySubjectListItemImage';
import CartSummarySubjectListItemInlineParameters from '../../CartSummaryPage/components/CartSummarySubjectListItemInlineParameters';
import CartSummarySubjectListItemName from '../../CartSummaryPage/components/CartSummarySubjectListItemName';
import CartSummarySubjectListItemPrice from '../../CartSummaryPage/components/CartSummarySubjectListItemPrice';
import CartSummarySubjectListItemTotalPrice from '../../CartSummaryPage/components/CartSummarySubjectListItemTotalPrice';
import CartSummarySubjectListItemWarning from '../../CartSummaryPage/components/CartSummarySubjectListItemWarning';

export interface CartApprovalSubjectListItemProps extends StandardDivProps<CartApprovalSubjectListItemClassKey> {
  subject: ShoppingCartSubject;
}

export type CartApprovalSubjectListItemClassKey = 'root';

const themeSettings = { name: 'CartApprovalSubjectListItem' };

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),

      borderBottom: `2px solid ${darken(theme.palette.divider, 0.4)}`,
      '&:last-of-type': {
        borderBottom: 'none',
        marginBottom: 0,
      },

      display: 'grid',
      gridGap: 0,
      justifyItems: 'stretch',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      gridTemplateRows: 'max-content',
      gridTemplateColumns: '144px 1fr 160px 150px 160px 48px',
      gridTemplateAreas: `
        "image name       name        name        name       actions"
        "image parameters price       addToCart   totalPrice actions"
        "image variants    variants     .     .    ."
      `,

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto',
        justifyItems: 'center',
        gridTemplateAreas: `
          "image          image"
          "name           name"
          "parameters     parameters"
          "price          totalPrice"
          "addToCart      addToCart"
          "actions        actions"
        `,
      },
      [theme.breakpoints.down('xs')]: {
        justifyItems: 'flex-start',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr 48px',
        gridTemplateAreas: `
          "name           name              name            actions"
          "image          image             image           image"
          "parameters     parameters        parameters      parameters"
          "price          price             totalPrice      totalPrice"
          "addToCart      addToCart         addToCart       addToCart"
          "variants       variants          variants        variants"
        `,
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-columns': '144px auto auto',
      },
    },
    image: {
      gridArea: 'image',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 1 / 2,
        '-ms-grid-column': 1,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 2,
          '-ms-grid-column': 1 / 4,
        },
      },
    },
    name: {
      gridArea: 'name',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 1,
        '-ms-grid-column': 2 / 4,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 2,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 1 / 3,
        },
      },
    },
    parameters: {
      gridArea: 'parameters',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 2,
        '-ms-grid-column': 2,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 3,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 3,
          '-ms-grid-column': 1 / 4,
        },
      },
    },
    price: {
      gridArea: 'price',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 2,
        '-ms-grid-column': 3,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 1,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 1 / 2,
        },
      },
    },
    totalPrice: {
      gridArea: 'totalPrice',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 2,
        '-ms-grid-column': 5,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 3 / 4,
        },
      },
    },
    addToCart: {
      gridArea: 'addToCart',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 2,
        '-ms-grid-column': 4,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 5,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 5,
          '-ms-grid-column': 1 / 4,
        },
      },
    },
    actions: {
      gridArea: 'actions',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 1 / 2,
        '-ms-grid-column': 6,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 6,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 4,
        },
      },
    },
    variants: {
      gridArea: 'variants',
      marginTop: '1rem',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '-ms-grid-row': 1 / 2,
        '-ms-grid-column': 7,
      },
      [theme.breakpoints.down('md')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 6,
          '-ms-grid-column': 1 / 2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 5,
        },
      },
    },
  }),
  themeSettings,
);

const CartApprovalSubjectListItem = React.forwardRef<HTMLDivElement, CartApprovalSubjectListItemProps>(
  ({ className, classes: pClasses, subject }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <CartSummarySubjectListItemImage className={classes.image} subject={subject} />
        <CartSummarySubjectListItemName className={classes.name} subject={subject} />
        <CartSummarySubjectListItemInlineParameters className={classes.parameters} subject={subject} />
        <CartSummarySubjectListItemPrice className={classes.price} subject={subject} />
        <CartSummarySubjectListItemTotalPrice className={classes.totalPrice} subject={subject} />
        <CartSummarySubjectListItemWarning className={classes.variants} subject={subject} />
      </div>
    );
  },
);

CartApprovalSubjectListItem.displayName = 'CartApprovalSubjectListItem';

export default withThemeProps(themeSettings)(CartApprovalSubjectListItem);
